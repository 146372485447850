import React from "react";
import Footer from "../_generic/Footer";
import ReactEcharts from "echarts-for-react";
import { Layout } from "../_generic/Layout";
import { getHorizontalBarOptions } from "../_generic/chartOptions";
import { RightSideImageLayout } from "../_generic/RightSideImageLayout";

export const CategoryDistribution = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        h2 = "ERROR",
        h3 = "ERROR",
        data = [],
        imageURL = "https://res.cloudinary.com/djgzit41g/image/upload/v1701935339/ICE/woman_light_eye_cxzqph.png",
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <RightSideImageLayout
                imageURL={imageURL}
                imageShadowColor="#D2AFFF"
            >
                <div className="space-y-8">
                    <div>
                        <h1 className="text-black text-17xl font-semibold leading-[54px]">
                            {h1}
                        </h1>
                        <h2 className="text-gray-500 text-sm">{h2}</h2>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold text-black">
                            {h3}
                        </h3>
                        <ReactEcharts
                            option={getHorizontalBarOptions({ data })}
                            opts={{ renderer: "svg" }}
                            style={{ width: "600px", height: "420px" }}
                        />
                    </div>
                </div>
            </RightSideImageLayout>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default CategoryDistribution;
