import ToolbarSideBarButton from "./ToolbarSideBarButton";
import { useDrag } from "react-dnd";
import ToolbarCreateCommentButton from "./ToolbarCreateCommentButton";

import ToolBarAvatarButton from "./ToolBarAvatarButton";

import ToolbarDropdownButton from "./ToolbarDropdownButton";

import ToolbarShareButton from "./ToolbarShareButton";

const Toolbar = ({ left, bottom, children }) => {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: "box",
            item: { left, bottom },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [left, bottom],
    );
    if (isDragging) {
        return <div ref={drag} />;
    }
    return (
        <div
            data-velt-comment-disabled
            ref={drag}
            style={{ left, bottom }}
            data-testid="box"
            className="box fixed bottom-0 z-10 flex justify-center mb-5 left-1/2 min-h-[50px]"
        >
            <div className="relative rounded-[30px] [background:linear-gradient(180deg,_#555,_#323232_99.99%)] shadow-[0px_1px_10px_rgba(0,_0,_0,_0.25)] box-border flex flex-row py-2 px-8 items-center justify-center gap-[16px] border-[1px] border-solid border-white">
                <ToolbarCreateCommentButton />
                <ToolbarSideBarButton />
                <ToolBarAvatarButton />
                <ToolbarShareButton />
                <ToolbarDropdownButton />
            </div>
        </div>
    );
};

export default Toolbar;
