import React from "react";
import Footer from "../_generic/Footer";
import { mapCurrency } from "../../../utils/formatCurrency";
import { TwoColumnLayout } from "../_generic/TwoColumnLayout";
import { Layout } from "../_generic/Layout";
import { PricingDistributionBar } from "../_generic/PricingDistributionBar";

export const PricingAnalysis = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let { title = "ERROR", subTitle = "ERROR", blocks = [] } = slideContent;

    return (
        <Layout showFull={showFull}>
            <TwoColumnLayout
                color="#E7DEFA"
                title={
                    <div className="space-y-2">
                        <h1 className="text-black text-17xl font-semibold leading-[54px]">
                            {title}
                        </h1>
                        <h2 className="text-gray-500 text-sm">{subTitle}</h2>
                    </div>
                }
                leftSide={
                    <div className="space-y-8">
                        <h3 className="text-lg font-medium text-black uppercase">
                            {blocks[0].title}
                        </h3>

                        <div className="space-y-4">
                            {blocks[0].data.map((item, index) => (
                                <PricingDistributionBar
                                    key={index}
                                    label={mapCurrency(item.attr)}
                                    value={item.value}
                                    foregroud="#484CBC"
                                />
                            ))}
                        </div>

                        <p className="italic text-xs text-[#3A3A3A]">
                            {blocks[0].note}
                        </p>
                    </div>
                }
                rightSide={
                    <div className="space-y-8">
                        <h3 className="text-lg font-medium text-black uppercase">
                            {blocks[1].title}
                        </h3>

                        <div className="space-y-4">
                            {blocks[1].data.map((item, index) => (
                                <PricingDistributionBar
                                    key={index}
                                    label={mapCurrency(item.attr)}
                                    value={item.value}
                                    foregroud="#9D48BC"
                                    background="#fff"
                                />
                            ))}
                        </div>

                        <p className="italic text-xs text-[#3A3A3A]">
                            {blocks[1].note}
                        </p>
                    </div>
                }
            />
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default PricingAnalysis;
