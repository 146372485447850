function NoAccess() {
    const logo = `${process.env.PUBLIC_URL}/eyva_logo_no_access.svg`; // replace 'logo.png' with your logo file name

    return (
        <div class="h-screen w-screen bg-black flex justify-center items-center flex-col">
            <div class="relative inline-flex group">
                <div class="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
                <a
                    href={"https://app.eyva.ai/login"}
                    rel="noopener noreferrer"
                    target="_blank"
                    class="relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-200 font-pj rounded-xl focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    role="button"
                >
                    <img src={logo} className="h-5" alt="Eyva Logo" />
                </a>
            </div>
            <div className="flex flex-col items-center justify-center my-6 text-center">
                <p className="p-1 text-xs text-white">
                    You don't have access to this report.
                </p>
                <p className="text-xs text-white">
                    If you are an eyvian, click on the logo to get to the login
                    page.
                </p>
            </div>
        </div>
    );
}

export default NoAccess;
