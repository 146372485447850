import defaultaxios from "axios";
import { getCookie } from "react-use-cookie";

export const mapEyvaUserToVeltUser = (eyvaUser) => {
    try {
        return {
            userId: eyvaUser.userId,
            displayName: eyvaUser.userName,
            photoUrl: eyvaUser.userAvatarUrl,
            email: eyvaUser.userEmail,
            groupId: eyvaUser.companyId.replace(/-/g, ""),
        };
    } catch (error) {
        return {
            userId: new Date().getTime().toString(),
            displayName: "Anonymous",
            photoUrl: "https://vercel.com/api/www/avatar/?u=anonymous&s=64",
            email: "default@anonymous.com",
            groupId: "Anonymous",
        };
    }
};

export const calculateMaxScale = () => {
    const width = window.innerWidth;
    if (width <= 640) return 0.25;
    else if (width <= 800) return 0.375;
    else if (width <= 960) return 0.5;
    else if (width <= 1366) return 0.75;
    else return 1;
};

const getJwtToken = () => {
    const token = getCookie("token_reporting"); // Replace 'jwt' with the actual cookie name

    // TODO: This is just for testing
    // if (!token)
    //     return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3NWYwZjU3LWY0NjctNGIwMS1iOGU2LWY0NmE4OTM4NjZlMiIsImVtYWlsIjoid2lsaGVsbS5yYWlkZXJAZGF0YXplaXQuaW8iLCJyb2xlIjoiRW1wbG95ZWUiLCJpYXQiOjE2OTM4Mzc2MzV9.LI3w3yJbMjRZcy0ywcb8IGYYAErXHAIaNWarFKqLIJs";

    return token;
};

export const axios = defaultaxios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getJwtToken()}`,
    },
});
