import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

const TableOfContents = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        imageURL = "https://res.cloudinary.com/djgzit41g/image/upload/v1701935339/ICE/woman_light_eye_cxzqph.png",
        textHTML = "ERROR",
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="w-full">
                <div className="flex gap-5">
                    <div className="flex flex-col items-stretch w-1/2 ml-10">
                        <div className="flex flex-col items-stretch px-5 mt-20">
                            <h1 className="text-black text-17xl font-semibold leading-[54px]">
                                {h1}
                            </h1>
                            {/* Force Tailwind to include these classes in the build */}
                            <div className="hidden ml-5 list-disc list-inside"></div>
                            <div className="mt-10 text-base leading-8 text-black">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: textHTML,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col self-stretch w-1/2">
                        <div className="flex-col justify-center self-stretch overflow-hidden relative flex min-h-[690px] max-h-[690px]">
                            <img
                                loading="lazy"
                                srcSet={imageURL}
                                className="object-cover"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TableOfContents;
