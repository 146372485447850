import React from "react";
import { classNames } from "../../../utils/classNames";

export const CoverSlideLayout = ({
    children,
    imageURL,
    footer,
    dark = true,
}) => {
    return (
        <div
            className={classNames(
                "relative w-full h-[720px] overflow-hidden flex flex-col items-end justify-end w-1280 min-w-[1280px] max-w-[1280px] min-h-[720px] max-h-[720px] text-left text-black font-poppins",
                dark ? "text-black" : "text-white",
            )}
        >
            <img
                className="absolute top-0 left-0 w-full h-full z-0 object-cover"
                src={imageURL}
            />
            <div className="self-stretch flex-1 flex flex-col py-0 px-[45px] items-end justify-end relative">
                <div className="my-0 absolute top-[calc(50%_-_29px)] left-[calc(50%_+_405px)] flex flex-col items-end justify-start z-[1]">
                    <img
                        className="relative w-[180px] h-[57.39px]"
                        alt=""
                        src={dark ? "/eyva_logo.svg" : "/eyva_logo_cover.svg"}
                    />
                </div>
            </div>
            <div
                className={classNames(
                    "relative self-stretch h-[550px] flex flex-col pt-0 px-[100px] pb-[104px] items-start justify-end border-y-2 ",
                    dark ? "border-black" : "border-white",
                )}
            >
                {children}
            </div>
            {footer}
        </div>
    );
};
