import React, { useState } from "react";

import { debounce } from "lodash";

import ReactWordcloud from "react-wordcloud-upsiide-fork";

import { formatNumber, showGrowth } from "../../../utils/numbers";

import Footer from "../_generic/Footer";

const options = {
    //colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    enableTooltip: false,
    deterministic: true,
    fontFamily: "impact",
    fontSizes: [10, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    //padding: 1,
    rotations: 3,
    //rotationAngles: [0, 0],
    scale: "log", // sqrt, log, linear
    spiral: "archimedean", // archimedean, rectangular
    transitionDuration: 0,
};

const Row = ({ keyword, value }) => {
    return (
        <div className="flex flex-row items-start self-stretch justify-start h-11">
            <div className="self-stretch box-border w-[470px] flex flex-row items-center justify-start p-2.5 border-t-[1px] border-solid border-gainsboro border-b-[1px]">
                <img
                    className="relative self-stretch flex-1 hidden object-cover max-w-full max-h-full overflow-hidden"
                    alt=""
                    src="/product-photo@2x.png"
                />
                <div className="relative">{keyword}</div>
            </div>
            <div className="self-stretch bg-whitesmoke-100 box-border w-[70px] flex flex-row items-center justify-center p-2.5 text-right text-royalblue border-t-[1px] border-solid border-gainsboro border-b-[1px]">
                <div className="relative font-medium">
                    {formatNumber(value)}
                </div>
            </div>
        </div>
    );
};

const colors = {};

const Slide = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
}) => {
    if (!slideContent) slideContent = {};
    let { phrases, keywords } = slideContent;

    let deduplicatedKeywords = keywords.reduce((acc, current) => {
        const x = acc.find((item) => item.keyword === current.keyword);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);

    deduplicatedKeywords.sort((a, b) => b.value - a.value);
    const defaultKeywordsList = deduplicatedKeywords.slice(0, 10);

    const [keywordsList, setKeywordsList] = useState(defaultKeywordsList);

    phrases = phrases.map((word) => ({ ...word, text: word.phrase }));

    // Sort phrases by value in descending order
    phrases.sort((b, a) => b.value - a.value);
    // Find the maximum value for reference in the color calculation
    const maxValue = Math.max(...phrases.map((word) => word.value));

    // Create first a map of colors for each phrase with a random color and opacity

    const getRandomColor = (value) => {
        // Calculate random shade of green and red for other phrases
        const opacity = (value / maxValue) ** 0.07 || 1; // Adjust exponent for different shading effects
        const red = Math.floor(Math.random() * 256); // Random number between 0 and 255
        const green = Math.floor(Math.random() * 256); // Random number between 0 and 255
        return `rgba(${red}, ${green}, 255, ${opacity.toFixed(1)})`;
    };

    // Only populate the colors object if it's empty
    if (Object.keys(colors).length === 0) {
        phrases.forEach((phrase) => {
            colors[phrase.value] = getRandomColor(phrase.value);
        });
    }

    // Function to assign a color based on the word's value
    const getShade = (value) => {
        if (value === maxValue) {
            // Assign white to the most frequent word
            return "rgba(0, 0, 255, 1)";
        } else {
            return colors[value];
        }
    };

    const onWordMouseOverCallback = debounce((value) => {
        const filteredKeywords = deduplicatedKeywords.filter((keyword) =>
            keyword.keyword.includes(value),
        );
        filteredKeywords.sort((a, b) => b.value - a.value);
        setKeywordsList(filteredKeywords.slice(0, 10));
        return true;
    }, 20); //  debounce time

    const onWordMouseOutCallback = () => {
        setKeywordsList(defaultKeywordsList);
    };

    const callbacks = {
        getWordColor: (word) => getShade(word.value),
        onWordMouseOver: (word) => onWordMouseOverCallback(word.phrase),
        onWordMouseOut: () => onWordMouseOutCallback(),
        getWordTooltip: (word) =>
            `${word.text} (${word.value}) [${word.value > 50 ? "" : ""}]`,
    };

    return (
        <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start min-w-[1280px] max-w-[1280px] text-left text-[36px] text-black font-poppins">
            <div className="self-stretch flex flex-col items-center justify-start pt-[60px] px-[60px] pb-[34px] box-border gap-[10px] min-h-[690px]">
                <div className="flex flex-col items-start self-stretch justify-end">
                    <div className="flex flex-row items-center justify-start">
                        <div className="relative font-semibold">
                            Most Used Search Phrases
                        </div>
                    </div>
                    <div className="relative text-[18px] text-darkslategray">
                        Hover over the word cloud to see keywords ✌️
                    </div>
                </div>
                <div className="self-stretch h-[532px] flex flex-row flex-wrap items-start justify-start gap-[10px] text-base">
                    <div className="flex flex-row items-start justify-start gap-[10px]">
                        <div className="w-[600px] h-[500px]">
                            <ReactWordcloud
                                words={phrases}
                                options={options}
                                callbacks={callbacks}
                            />
                        </div>
                        <div className="flex flex-col items-center justify-start pt-0 px-0 pb-2.5">
                            <div className="flex flex-row items-start self-stretch justify-start h-12">
                                <div className="self-stretch w-[470px] flex flex-row items-center justify-start p-2.5 box-border">
                                    <div className="relative font-medium">
                                        Keyword
                                    </div>
                                </div>
                                <div className="self-stretch w-[70px] flex flex-row items-center justify-center p-2.5 box-border text-right">
                                    <div className="relative font-medium">
                                        SV
                                    </div>
                                </div>
                            </div>
                            {keywordsList.map((keyword, i) => (
                                <Row
                                    keyword={keyword.keyword}
                                    value={keyword.value}
                                    key={i}
                                />
                            ))}

                            {/* {Array.from({ length: 10 }, (_, i) => (
                                <Row
                                    keyword={`Keyword ${i + 1}`}
                                    value={(i + 1) * 1000}
                                    key={i}
                                />
                            ))} */}
                        </div>
                    </div>
                    <div className="flex flex-row items-start justify-start px-0 pt-2 pb-0 text-center">
                        <div className="relative flex items-end justify-center w-[1059px] shrink-0">{`*Data is truncated and showing only the phrases and keywords with highest search volume. `}</div>
                    </div>
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </div>
    );
};

export default Slide;
