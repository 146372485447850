import Footer from "./Footer";

const ErrorSlide = () => {
    return (
        <div className="relative bg-white w-[1280px] flex flex-col items-center justify-start min-w-[1280px] max-w-[1280px] text-left text-41xl text-black font-poppins">
            <div className="self-stretch flex flex-row items-start justify-center min-h-[690px]">
                <div className="flex-1 flex flex-row p-[60px] items-start justify-center">
                    We are sorry. Something went wrong. Please contact our
                    support.
                </div>
                <img
                    className="self-stretch max-h-full w-[450px] object-cover"
                    alt=""
                    src="/photo@2x.png"
                />
            </div>
            <Footer
                slideName={""}
                reportName={"Some error happened"}
                reportVersion={""}
                reportCreatedAt={new Date().toISOString()}
                reportLang={"en"}
            />
        </div>
    );
};

export default ErrorSlide;
