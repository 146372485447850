import React from "react";
import * as RSwitch from "@radix-ui/react-switch";

const Switch = ({ checked = false, onCheckedChange }) => (
    <div className="flex items-center">
        <RSwitch.Root
            checked={checked}
            onCheckedChange={onCheckedChange}
            className="w-[36px] h-[20px] bg-blackA6 rounded-full relative shadow-[0_2px_10px] shadow-blackA4 focus:shadow-[0_0_0_2px]  data-[state=checked]:bg-eyvablue outline-none cursor-default"
            id="airplane-mode"
        >
            <RSwitch.Thumb className="block w-[16px] h-[16px] bg-white rounded-full shadow-[0_2px_2px] shadow-blackA4 transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[18px]" />
        </RSwitch.Root>
    </div>
);

export default Switch;
