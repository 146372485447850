import React from "react";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

export const TopBrands = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        h2 = "ERROR",
        brandsWithLowPrice = [],
        brandsWithHighPrice = [],
        lastDataUpdate = "ERROR",
        imageURL = "https://res.cloudinary.com/djgzit41g/image/upload/v1701935339/ICE/woman_light_eye_cxzqph.png",
    } = slideContent;

    return (
        <Layout showFull={showFull} className="relative">
            <div className="p-10 min-h-[690px] w-full space-y-8">
                <div>
                    <h1 className="text-black text-17xl font-semibold leading-[54px]">
                        {h1}
                    </h1>
                    <h2 className="text-gray-500 text-sm">{h2}</h2>
                </div>
                <div className="grid grid-cols-2 gap-10 w-3/4 text-black text-sm">
                    <div className="border border-gray-200">
                        <div className="flex w-full justify-between items-center  px-4 py-3 border-b border-gray-200">
                            <p className="text-red-500 font-semibold">
                                TOP BRANDS WITH LOWEST PRICE
                            </p>
                            <p className="text-gray-500 text-xs">
                                Price per 1 l
                            </p>
                        </div>

                        <div className="divide-y border-gray-200">
                            {brandsWithLowPrice.map((item, index) => (
                                <div
                                    key={index}
                                    className="flex justify-between items-center px-4 py-3"
                                >
                                    <p>{item.brand}</p>
                                    <p>{item.price}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="border border-gray-200">
                        <div className="flex w-full justify-between items-center  px-4 py-3 border-b border-gray-200">
                            <p className="text-purple-500 font-semibold">
                                TOP BRANDS WITH HIGHEST PRICE
                            </p>
                            <p className="text-gray-500 text-xs">
                                Price per 1 l
                            </p>
                        </div>

                        <div className="divide-y border-gray-200">
                            {brandsWithHighPrice.map((item, index) => (
                                <div
                                    key={index}
                                    className="flex justify-between items-center px-4 py-3"
                                >
                                    <p>{item.brand}</p>
                                    <p>{item.price}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TopBrands;
