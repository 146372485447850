import Lottie from "lottie-react";
import loadingAnimationGroovy from "../../assets/lottie/groovyWalk.json";
import processAnimation from "../../assets/lottie/process.json";
import loadingAnimationCubic from "../../assets/lottie/animationCubic.json";

const mapTypeToAnimation = {
    loading: loadingAnimationCubic,
    process: loadingAnimationCubic,
};

function Loading({ type = "loading" }) {
    const animationData = mapTypeToAnimation[type];

    const mapTypeToText = {
        loading: "",
        processing:
            "eyva is generating your report. You'll get a notification when it's ready.",
    };

    const text = mapTypeToText[type];

    return (
        <div className="flex flex-col items-center justify-center w-full min-h-screen text-white bg-black">
            <div className="w-[200px]">
                <Lottie animationData={animationData} loop={true} />
            </div>
            <div className="text-xs text-white">{text}</div>
        </div>
    );
}

export default Loading;
