import React, { useEffect, useState } from "react";
import Footer from "../_generic/Footer";
import { HeartIcon, ChatIcon, LinkIcon } from "@heroicons/react/outline";
import { Layout } from "../_generic/Layout";
import { formatNumber } from "../../../utils/formatNumber";
import { APP_URL } from "../../../utils/constants";
import ShowAllButton from "../_generic/ShowAllButton";
import Switch from "../../ui/Switch";

export const PostCard = ({
    profileImg,
    username,
    description,
    imgURL,
    link,
    likes,
    comments,
    products,
}) => {
    return (
        <div className="border text-sm overflow-clip relative rounded-md border-gray-300 w-full">
            <div className="border-t-[6px] border-t-[#D34E62] border-b border-b-gray-300">
                {(profileImg || username) && (
                    <div className="flex items-center space-x-3 py-3 px-4">
                        <img
                            src={profileImg ?? "/no-img-found.png"}
                            className="rounded-full object-contain w-7 h-7"
                        />
                        <p className="">@{username}</p>
                    </div>
                )}
            </div>
            <div className="space-y-4 p-4">
                <p className="line-clamp-3 text-xs text-gray-500">
                    {description}
                </p>
                <div className="relative">
                    <img
                        src={imgURL}
                        className="w-full h-44 object-cover rounded-md"
                    />
                    <a
                        className="absolute bottom-3 text-xs font-medium -translate-x-1/2 left-1/2 whitespace-nowrap text-eyvablue bg-white rounded-full px-3 py-1"
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        View Post
                    </a>
                </div>
                <div className=" text-black divide-y border-gray-200">
                    <div className="flex justify-between pb-3">
                        <p>Likes</p>
                        <div className="flex space-x-1 items-center">
                            <HeartIcon className="h-4 w-4" />
                            <p className="">{formatNumber(likes)}</p>
                        </div>
                    </div>
                    <div className="flex justify-between py-3">
                        <p>Comments</p>
                        <div className="flex space-x-1 items-center">
                            <ChatIcon className="h-4 w-4" />
                            <p className="">{formatNumber(comments)}</p>
                        </div>
                    </div>
                    <div className="pt-3">
                        {products.length > 0 ? (
                            <div className="grid grid-cols-4 gap-3">
                                {products.map((product, i) => (
                                    <div className="space-y-2" key={i}>
                                        <img
                                            className="object-contain w-full h-14"
                                            src={product.imgURL}
                                        ></img>
                                        <div className="flex justify-center">
                                            <a
                                                href={`${APP_URL}product-details/${product.p_c_id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="font-medium text-eyvablue"
                                            >
                                                Link
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const TopPosts = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        h2 = "ERROR",
        datePeriod = "ERROR",
        posts: allPosts = [],
    } = slideContent;

    const [showWOproducts, setShowWOproducts] = useState(false);
    const posts = allPosts.filter((item) =>
        showWOproducts ? item.products.length > 0 : true,
    );

    const maxCount = posts.length;
    const minCount = 4;
    const totalCount = posts.length;
    const [displayCount, setDisplayCount] = useState(minCount);
    useEffect(() => {
        if (showFull) {
            setDisplayCount(maxCount);
        }
    }, []);

    return (
        <Layout showFull={showFull}>
            <div className="min-h-[690px] px-10 py-8 space-y-6 w-full">
                <div>
                    <div className="flex justify-between items-center">
                        <h1 className="text-black text-17xl font-semibold leading-[54px]">
                            {h1}
                        </h1>
                        <div className="flex items-center space-x-2">
                            <p className="text-xs text-gray-500 font-medium">
                                Show only posts with detected products.
                            </p>
                            <Switch
                                checked={showWOproducts}
                                onCheckedChange={(checked) =>
                                    setShowWOproducts(checked)
                                }
                            />
                        </div>
                    </div>
                    {/* <p className="text-gray-500 text-sm">{h2}</p> */}
                    <p className="text-black text-sm">{datePeriod}</p>
                </div>
                <div className="grid grid-cols-4 gap-4 text-black">
                    {posts.slice(0, displayCount).map((post, index) => (
                        <PostCard key={index} {...post} />
                    ))}
                </div>
                <div className="flex w-full justify-center">
                    <ShowAllButton
                        displayCount={displayCount}
                        maxCount={maxCount}
                        handleShowAll={() => setDisplayCount(totalCount)}
                        totalCount={totalCount}
                    ></ShowAllButton>
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TopPosts;
