import React from "react";
import { classNames } from "../../../utils/classNames";

export const Layout = ({ showFull = false, children, className }) => {
    return (
        <div
            className={classNames(
                `flex flex-col items-center bg-white font-poppins w-1280 min-w-[1280px] max-w-[1280px] ${
                    showFull ? "overflow-visible" : "overflow-auto"
                }`,
                className,
            )}
        >
            {children}
        </div>
    );
};
