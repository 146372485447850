// import * as Tooltip from "@radix-ui/react-tooltip";
import {
    GlobeIcon,
    Cross2Icon,
    CopyIcon,
    LockOpen1Icon,
    LockClosedIcon,
    Link1Icon,
    Link2Icon,
} from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import ToolTip from "../ToolTip";

import React, { useState, useEffect } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";

import { useReportData, useUserData } from "../../../stores/reportStore";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../utils/helpers";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "./Select";

const checkIfUserIsAllowedToMakeReportPublic = (userInfo) => {
    return (
        userInfo?.loggedInUser?.allowedActionsForReportId.filter(
            (action) => action === "MAKE_PUBLIC",
        ).length > 0
    );
};

export const RadioItem = ({ children, value }) => {
    return (
        <div className="flex items-center px-5 py-4">
            <RadioGroup.Item
                className="w-[18px] h-[18px] rounded-full shadow-black focus:shadow-black cursor-default shadow-[0_0_0_1px] outline-none focus:shadow-[0_0_0_2px] bg-mauve1"
                value={value}
            >
                <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[10px] after:h-[10px] after:rounded-[50%] after:bg-black" />
            </RadioGroup.Item>
            <label className=" text-[15px] leading-none pl-4 w-full">
                {children}
            </label>
        </div>
    );
};

export const PermissionControl = () => {
    const { isLoading, error, reportData, reportPermissions } = useReportData();
    const { userLoading, userError, isLoggedIn, userInfo } = useUserData();

    const userId = userInfo?.loggedInUser?.userId;
    const reportId = reportData?.reportId;
    // const userAllowedSharePublic =
    //     checkIfUserIsAllowedToMakeReportPublic(userInfo);

    const [action, setAction] = useState("MAKE_PRIVATE");

    const { isLoading: isSubmitting, mutate } = useMutation({
        mutationFn: (data) => {
            return axios.post("/api/reports/action", data);
        },
    });

    useEffect(() => {
        setAction(reportPermissions?.isPublic ? "MAKE_PUBLIC" : "MAKE_PRIVATE");
    }, [reportPermissions]);

    return (
        <>
            <Select
                value={action}
                onValueChange={(action) => {
                    setAction(action);
                    mutate({ reportId, userId, action });
                }}
            >
                <SelectTrigger className="w-[280px] text-sm">
                    <SelectValue placeholder="Select Permissions" />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem value="MAKE_PRIVATE">
                            Only team members
                        </SelectItem>
                        <SelectItem value="MAKE_PUBLIC">
                            Anyone with the link
                        </SelectItem>
                    </SelectGroup>
                </SelectContent>
            </Select>

            <div className="flex justify-between shrink-0 ">
                <button
                    className="flex items-center px-4 py-2 space-x-2 border rounded-md outline-none hover:bg-gray-100 bg-gray-50 border-gray-200 text-blue text-sm"
                    onClick={() =>
                        navigator.clipboard.writeText(window.location.href)
                    }
                >
                    <Link2Icon />
                    <p>Copy Link</p>
                </button>
                <Dialog.Close asChild>
                    <button className="flex items-center px-8 py-2 space-x-2 border rounded-md outline-none text-white bg-gray-900 hover:bg-black text-sm">
                        <p>Done</p>
                    </button>
                </Dialog.Close>
            </div>
        </>
    );
};

const DialogButton = ({ isLoggedIn }) => (
    <Dialog.Root>
        <Dialog.Trigger>
            <ToolTip.Root>
                <ToolTip.Trigger>
                    <button className="hover:bg-blue3 inline-flex h-[35px] w-[35px] items-center justify-center rounded-full bg-white outline-none focus:shadow-[0_0_0_2px] text-black">
                        <GlobeIcon />
                    </button>
                </ToolTip.Trigger>
                <ToolTip.Content>Share Report</ToolTip.Content>
            </ToolTip.Root>
        </Dialog.Trigger>
        <Dialog.Portal>
            <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0" />
            <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                    Share your Report
                </Dialog.Title>

                {isLoggedIn ? (
                    <div className="space-y-4">
                        <Dialog.Description className="text-mauve11 mt-[10px] mb-5 text-[15px] leading-normal">
                            Adjust the view permissions for this report by
                            selecting one of the options below.
                        </Dialog.Description>
                        <PermissionControl />
                    </div>
                ) : (
                    "You need to login to share the report "
                )}

                <Dialog.Close asChild>
                    <button
                        className="text-mauve11 hover:bg-mauve4 focus:shadow-mauve7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                        aria-label="Close"
                    >
                        <Cross2Icon />
                    </button>
                </Dialog.Close>
            </Dialog.Content>
        </Dialog.Portal>
    </Dialog.Root>
);

const CommentToolButton = () => {
    const { userLoading, userError, isLoggedIn, userInfo } = useUserData();

    return <DialogButton isLoggedIn={isLoggedIn} />;
};

export default CommentToolButton;
