import React from "react";
import Footer from "../_generic/Footer";
import { RightSideImageLayout } from "../_generic/RightSideImageLayout";
import { Layout } from "../_generic/Layout";

export const DataScope = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        html = "ERROR",
        date = "ERROR",
        category = "ERROR",
        country = "ERROR",
        bsr = "ERROR",
        sales = "ERROR",
        note = "ERROR",
        imageURL = "https://res.cloudinary.com/djgzit41g/image/upload/v1701935339/ICE/woman_light_eye_cxzqph.png",
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <RightSideImageLayout
                imageURL={imageURL}
                imageShadowColor="#FFDFB9"
            >
                <div className="space-y-14 flex flex-col h-full justify-center">
                    <div className="space-y-5">
                        <h1 className="text-black text-17xl font-semibold leading-[54px]">
                            {h1}
                        </h1>
                        <div
                            className="text-sm text-gray-600"
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                    </div>

                    <div className="space-y-5 text-black text-sm">
                        <p>
                            <span className="font-semibold">Date</span>: {date}
                        </p>
                        <p>
                            <span className="font-semibold">Category path</span>
                            : {category}
                        </p>
                        <p>
                            <span className="font-semibold">Country</span>:{" "}
                            {country}
                        </p>
                        <p>
                            <span className="font-semibold">
                                # - Amazon Best Sellers Rank (BSR)
                            </span>
                            : {bsr}
                        </p>
                        <p>
                            <span className="font-semibold">
                                Monthly sales estimations (Sales Est.)
                            </span>
                            : {sales}
                        </p>
                        <div dangerouslySetInnerHTML={{ __html: note }} />
                    </div>
                </div>
            </RightSideImageLayout>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default DataScope;
