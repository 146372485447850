import React from "react";

export const TwoColumnLayout = ({ title, rightSide, leftSide, color }) => {
    return (
        <div className="min-h-[690px] w-full flex flex-col">
            {title && (
                <div className="grid grid-cols-2">
                    <div className="p-10 h-fit">{title}</div>
                    <div className="h-full" style={{ background: color }}></div>
                </div>
            )}
            <div className="flex grow">
                <div className="w-1/2 p-10 h-full ">{leftSide}</div>
                <div className="grid w-1/2">
                    <div className="p-10 h-full" style={{ background: color }}>
                        {rightSide}
                    </div>
                </div>
            </div>
        </div>
    );
};
