// Function to format numbers into '1k', '1M', etc.
export const formatNumber = function (num) {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + "k";
    } else {
        return num.toString();
    }
};

export const showGrowth = (number) => {
    // Determine the sign of the number
    let sign = Math.sign(number) > 0 ? "+" : Math.sign(number) < 0 ? "-" : "";
    let color =
        Math.sign(number) > 0
            ? "text-green-500"
            : Math.sign(number) < 0
            ? "text-red-500"
            : "text-gray-500";

    const num = Math.abs(number).toLocaleString("de-DE", {
        maximumFractionDigits: 0,
    });

    return <span className={color}>{`${sign}${num}%`}</span>;
};
