import React, { useState } from "react";

import Footer from "../_generic/Footer";

import ShowAllButton from "../_generic/ShowAllButton";

const Block = ({ index = 0, header, paragraph }) => {
    const colors = ["bg-aliceblue", "bg-honeydew", "bg-lavender-100"];

    const color = colors[index % colors.length];

    return (
        <div className="flex flex-row items-start self-stretch justify-center">
            <div
                className={`self-stretch flex-1 ${color} flex flex-col items-start justify-start p-5 gap-[10px]`}
            >
                <div className="relative self-stretch font-medium">
                    {header}
                </div>
                <div
                    className="self-stretch relative text-lg leading-[32px]"
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                ></div>
            </div>
        </div>
    );
};

const Slide = ({
    slideContent,
    slideName,
    reportName,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    const { h1, h2, blocks } = slideContent;

    const maxCount = 12;

    const minCount = 2;

    const [displayCount, setDisplayCount] = useState(
        showFull ? maxCount : minCount,
    );

    const totalCount = blocks.length;

    const handleShowAll = () => setDisplayCount(maxCount);

    return (
        <div className="relative bg-white w-full flex flex-col items-center justify-start min-w-[1280px] max-w-[1280px] text-left text-[60px] text-black font-poppins">
            <div className="self-stretch flex flex-col items-center justify-start py-0 px-[60px] box-border min-h-[690px]">
                <div className="self-stretch flex flex-col items-start justify-start pt-[60px] px-0 pb-[30px]">
                    <div className="relative font-medium">{h1}</div>
                    <div className="relative text-lg text-darkslategray">
                        {h2}
                    </div>
                </div>
                <div className="w-[1280px] flex flex-col items-center justify-start pt-0 px-[60px] pb-[20px] box-border gap-[30px] text-17xl">
                    {blocks.slice(0, displayCount).map((block, i) => (
                        <Block
                            index={i}
                            header={block.header}
                            paragraph={block.paragraph}
                            key={i}
                        />
                    ))}
                </div>
                <div className="flex flex-row items-start justify-start py-[30px] px-0">
                    <ShowAllButton
                        displayCount={displayCount}
                        maxCount={maxCount}
                        handleShowAll={handleShowAll}
                        totalCount={totalCount}
                    />
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </div>
    );
};

export default Slide;
