import React, { useEffect, useMemo, useState } from "react";
import {
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import Footer from "../_generic/Footer";
import { Table } from "../_generic/Table";
import ShowAllButton from "../_generic/ShowAllButton";
import { Layout } from "../_generic/Layout";
import { formatNumberCompact } from "../../../utils/formatNumber";
export const ProductList = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let { h1 = "ERROR", note, rows = [] } = slideContent;

    const [columnVisibility, setColumnVisibility] = useState({
        tag: false,
    });

    const columns = React.useMemo(
        () => [
            { accessorKey: "tag" },
            {
                id: "srno",
                size: 10,
                header: () => <p className="text-center">#</p>,
                cell: (props) => {
                    const i =
                        props?.table
                            ?.getSortedRowModel()
                            ?.flatRows?.indexOf(props?.row) + 1;
                    return <>{i}</>;
                },
            },
            {
                header: () => <p className="text-center">Photo</p>,
                accessorKey: "imgURL",
                size: 70,
                enableSorting: false,
                cell: (cell) => {
                    const img = cell.getValue();
                    return (
                        <img
                            className="-my-2 mx-auto border border-gray-50 text-center"
                            src={img ?? "/no-img-found.png"}
                            alt="product"
                            style={{
                                objectFit: "contain",
                                width: "40px",
                                height: "40px",
                            }}
                        />
                    );
                },
            },
            {
                header: "Product Name",
                accessorKey: "title",
                enableSorting: false,
                meta: { className: "text-left" },
                cell: ({ getValue, row }) => {
                    const tag = row.getValue("tag");
                    return (
                        <div>
                            <p className="line-clamp-1">{getValue()}</p>
                            <p className="text-xs px-3 rounded bg-[#EBFFE8] w-fit">
                                {tag}
                            </p>
                        </div>
                    );
                },
            },
            {
                header: "Price",
                accessorKey: "price",
                size: 50,
                enableSorting: false,
                meta: { className: "text-left" },
                cell: ({ getValue, row }) => {
                    return <p className="whitespace-nowrap">{getValue()}</p>;
                },
            },
            {
                accessorKey: "discountedPrice",
                header: "Discounted Price",
                size: 30,
                enableSorting: false,
                meta: { className: "text-left" },
                cell: ({ getValue }) => getValue() ?? "/",
            },
            {
                header: "Price per l",
                size: 50,
                accessorKey: "pricePerL",
                meta: {
                    className: "text-left",
                    headerClassName: "text-left whitespace-nowrap",
                },
                cell: ({ getValue }) => getValue() ?? "-",
            },
            {
                header: "Reviews",
                size: 20,
                accessorKey: "reviews",
                meta: {
                    className: "text-left",
                    headerClassName: "text-left",
                },
                cell: ({ getValue }) => formatNumberCompact(getValue()),
            },
            {
                header: "Rating",
                size: 20,
                accessorKey: "rating",
                meta: {
                    className: "text-left",
                    headerClassName: "text-left",
                },
                cell: ({ getValue }) => formatNumberCompact(getValue()),
            },

            {
                size: 10,
                header: "Link",
                accessorKey: "link",
                cell: ({ getValue, row }) => {
                    return (
                        <a
                            href={getValue()}
                            target="_blank"
                            rel="noreferrer"
                            className=""
                        >
                            Link
                        </a>
                    );
                },
                meta: {
                    className: "text-center",
                    cellClassName: "text-eyvablue",
                },
            },
        ],
        [],
    );
    const maxCount = rows.length;

    const minCount = 7;

    const totalCount = rows.length;

    const [displayCount, setDisplayCount] = useState(minCount);

    useEffect(() => {
        if (showFull) {
            setDisplayCount(maxCount);
        }
    }, []);

    const data = useMemo(
        () => rows.slice(0, displayCount),
        [rows, displayCount],
    );

    const table = useReactTable({
        data: data,
        columns,
        state: {
            columnVisibility,
        },
        onColumnVisibilityChange: setColumnVisibility,
        defaultColumn: {
            size: 500, //starting column size
            minSize: 0, //enforced during column resizing
            maxSize: 800, //enforced during column resizing
        },
        enableSortingRemoval: false,
        enableMultiSort: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (
        <Layout showFull={showFull}>
            <div className="p-10 space-y-8 w-full min-h-[690px]">
                <div>
                    <h1 className="text-black text-17xl font-semibold leading-[54px]">
                        {h1}
                    </h1>
                    <h2 className="text-gray-500 text-sm">{note}</h2>
                </div>
                <div className="w-full text-[#11131A] font-normal text-sm">
                    <Table table={table} />
                </div>

                <div className="flex w-full justify-center">
                    <ShowAllButton
                        displayCount={displayCount}
                        maxCount={maxCount}
                        handleShowAll={() => setDisplayCount(totalCount)}
                        totalCount={totalCount}
                    ></ShowAllButton>
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default ProductList;
