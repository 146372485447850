export const getVerticalBarOptions = ({
    data,
    lang = "en",
    color = "#E393FF",
    rotateLabel = false,
}) => {
    // Extract dates for the x-axis from one of the time series

    const options = {
        animation: false,

        grid: {
            containLabel: true,

            ...(rotateLabel
                ? { bottom: 0 }
                : { top: 50, bottom: 50, left: "5%", right: "5%" }),
        },

        xAxis: {
            type: "category",
            data: data.map((item) => item.attr),
            axisLabel: {
                interval: 0,
                ...(rotateLabel && { rotate: 45 }), //If the label names are too long you can manage this by rotating the label.
            },
        },
        yAxis: {
            type: "value",
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        series: [
            {
                data: data.map((item) => item.value),
                itemStyle: { color },
                type: "bar",
                label: {
                    show: true,
                    position: "top",
                },
            },
        ],
    };

    return options;
};

export const getHorizontalBarOptions = ({
    data,
    lang = "en",
    color = "#E393FF",
}) => {
    // Extract dates for the x-axis from one of the time series

    const options = {
        animation: false,
        grid: {
            containLabel: true,
            top: 30,
            bottom: 50,
            left: "2%",
            right: "5%",
        },
        xAxis: {
            type: "value",
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        yAxis: {
            type: "category",
            data: data.map((item) => item.attr),
            inverse: true,
        },
        series: [
            {
                data: data.map((item) => item.value),
                itemStyle: { color },
                type: "bar",
                label: {
                    show: true,
                    position: "right",
                },
            },
        ],
    };

    return options;
};
