import React from "react";
import Footer from "../_generic/Footer";
import { TwoColumnLayout } from "../_generic/TwoColumnLayout";

export const DataRoom = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        h2 = "ERROR",
        textHTML = "ERROR",
        buttons = [],
        imageURL = "https://res.cloudinary.com/djgzit41g/image/upload/v1701935339/ICE/woman_light_eye_cxzqph.png",
        infoTextRight = "ERROR",
    } = slideContent;

    return (
        <div
            className={`flex flex-col items-center bg-white font-poppins w-1280 min-w-[1280px] max-w-[1280px] ${
                showFull ? "overflow-visible" : "overflow-auto"
            }`}
        >
            <TwoColumnLayout
                color="#F9FCEA"
                title={
                    <div>
                        <h1 className="text-black text-17xl font-semibold leading-[54px]">
                            {h1}
                        </h1>
                        <h2 className="text-gray-500 text-sm">{h2}</h2>
                    </div>
                }
                leftSide={
                    <div className="space-y-16">
                        <div
                            dangerouslySetInnerHTML={{ __html: textHTML }}
                            className="text-gray-800 text-sm"
                        />
                    </div>
                }
                rightSide={
                    <div className="space-y-16">
                        <div style={{ width: "560px", height: "100%" }}>
                            <img
                                loading="lazy"
                                srcSet={imageURL}
                                className="object-contain"
                            ></img>
                        </div>
                        <p className="italic text-sm">{infoTextRight}</p>
                        <button
                            className="bg-eyvablue px-6 py-3"
                            onClick={() =>
                                window.open(buttons[0]?.url, "_blank")
                            }
                        >
                            {buttons[0]?.text}
                        </button>
                    </div>
                }
            />
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </div>
    );
};

export default DataRoom;
