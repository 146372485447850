export const PricingDistributionBar = ({
    label,
    value,
    foregroud = "#155EEF",
    background = "#E7ECEF",
}) => {
    return (
        <div className="flex w-full items-baseline justify-between text-[#3A3A3A] text-sm">
            <p className={"w-40 text-sm font-normal text-primary-900"}>
                {label}
            </p>
            <div className="w-full rounded-lg">
                <div
                    className="relative rounded-none"
                    style={{
                        background,
                        height: "10px",
                        width: "100%",
                    }}
                >
                    <div
                        className="absolute left-0 top-0 rounded-none"
                        style={{
                            background: foregroud,
                            height: "10px",
                            width: value + "%",
                        }}
                    ></div>
                </div>
            </div>
            <p
                className={
                    "w-24 text-right text-sm font-normal text-primary-900"
                }
            >
                {Math.round(value * 100) / 100}%
            </p>
        </div>
    );
};
