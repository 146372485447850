import { VeltCommentTool } from "@veltdev/react";
import * as Tooltip from "@radix-ui/react-tooltip";
import {
    Pencil2Icon,
    GlobeIcon,
    CopyIcon,
    Cross2Icon,
    ExternalLinkIcon,
} from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";

import useStore, { useUserData } from "../../../stores/reportStore";

import ToolTip from "../ToolTip";

const CreateCommentButton = () => {
    return (
        <Tooltip.Provider>
            <Tooltip.Root delayDuration={0}>
                <Tooltip.Trigger asChild>
                    <button className="hover:bg-blue3 inline-flex h-[35px] w-[35px] items-center justify-center rounded-full bg-white text-black outline-none focus:shadow-[0_0_0_2px]">
                        <Pencil2Icon />
                    </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        className="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-blue11 select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
                        sideOffset={16}
                    >
                        Create Comment
                        <Tooltip.Arrow className="fill-white" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};

const DialogButtonNotLoggedUser = () => (
    <Dialog.Root>
        <Dialog.Trigger>
            <ToolTip.Root>
                <ToolTip.Trigger>
                    <button className="hover:bg-blue3 inline-flex h-[35px] w-[35px] items-center justify-center rounded-full bg-white outline-none focus:shadow-[0_0_0_2px] text-black">
                        <Pencil2Icon />
                    </button>
                </ToolTip.Trigger>
                <ToolTip.Content>Create Comment</ToolTip.Content>
            </ToolTip.Root>
        </Dialog.Trigger>
        <Dialog.Portal>
            <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0" />
            <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[600px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                    Please Login into eyva
                </Dialog.Title>
                <Dialog.Description className="text-mauve11 mt-[10px] mb-5 text-[15px] leading-normal">
                    In order to use the collaboration features you must be
                    logged in as an eyvian ✌️
                </Dialog.Description>
                <a
                    href="https://app.eyva.ai/login"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center px-4 py-2 space-x-2 border rounded-md outline-none hover:bg-blue3 bg-blue2 border-blue11 text-blue "
                >
                    <ExternalLinkIcon />
                    <p>Click here to login</p>
                </a>

                <Dialog.Close asChild>
                    <button
                        className="text-mauve11 hover:bg-mauve4 focus:shadow-mauve7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                        aria-label="Close"
                    >
                        <Cross2Icon />
                    </button>
                </Dialog.Close>
            </Dialog.Content>
        </Dialog.Portal>
    </Dialog.Root>
);

const CommentToolButton = () => {
    const { userLoading, userError, isLoggedIn, userInfo } = useUserData();
    const { showComments } = useStore();
    // TODO: Remove this props
    return isLoggedIn ? (
        <VeltCommentTool
        // commentTool={showComments}
        // commentsOnDom={showComments}
        >
            <div slot="button">
                <CreateCommentButton />
            </div>
        </VeltCommentTool>
    ) : (
        <DialogButtonNotLoggedUser />
    );
};

export default CommentToolButton;
