import React, { useState } from "react";

import { formatNumber, showGrowth } from "../../../utils/numbers";

import Footer from "../_generic/Footer";

import ShowAllButton from "../_generic/ShowAllButton";

const Card = ({ index, name, value, growth, share, top }) => {
    /* EXAMPLE DATA
    {
        "name": "Olay",
        "value": 18000,
        "growth": 16.2,
        "top": {
            "keyword": "olay retinol 24",
            "value": 6600
        }
    }
    */

    // const colors = ["bg-aliceblue", "bg-honeydew", "bg-lavender-100"];
    const colors = [
        "bg-lime-100",
        "bg-aliceblue",
        "bg-red-100",
        "bg-gray-300",
        "bg-zinc-200",
        "bg-slate-200",
        "bg-amber-100",
        "bg-stone-200",
        "bg-lavender-100",
        "bg-honeydew",
        "bg-blue-100",
        "bg-zinc-200",
    ];
    const color = colors[index % colors.length];

    return (
        <div
            className={`rounded-md ${color} w-[378px] h-[250px] flex flex-row flex-wrap items-start justify-start p-5 box-border`}
        >
            <div className="hidden bg-aliceblue" />
            <div className="w-[188px] flex flex-row items-center justify-start text-eyvablue">
                <div className="relative leading-[30px] capitalize font-semibold inline-block w-[188px] shrink-0">
                    {name}
                </div>
            </div>
            <div className="w-[110px] h-[30px] flex flex-col items-end justify-center text-right">
                <div className="relative inline-block w-[110px] h-[30px] shrink-0">
                    <span className="font-medium">
                        <span className="leading-[30px]">
                            {formatNumber(value)}
                        </span>
                        <span className="text-xl leading-[35px]">{` `}</span>
                    </span>
                    <i className="text-sm leading-[30px] text-[10px] text-darkslategray">
                        (sv)
                    </i>
                </div>
            </div>
            <div className="flex flex-col items-start justify-center w-full">
                <div className="relative font-medium mb-[-15px]">{share}%</div>
            </div>
            <div className="relative text-[14px] leading-[21px] text-darkslategray inline-block w-[338px] shrink-0">
                of brand searches are including "{name}"
            </div>
            <div className="relative box-border w-[339px] h-px border-t-[1px] border-solid border-gainsboro" />
            <div className="relative text-[14px] leading-[21px] inline-block w-[338px] shrink-0 text-darkslategray">
                <span>{`eyva detected that consumers are mostly looking for: `}</span>
                <i className="font-medium text-eyvablue">{top?.keyword}</i>
                <span>{`, with total of `}</span>
                <span className="font-medium text-black">
                    {formatNumber(top?.value)}{" "}
                </span>
                <span>searches for this keyword.</span>
            </div>
        </div>
    );
};

const GrowingTopic = ({ name, growth }) => {
    return (
        <>
            <span className="font-medium">
                <span>
                    <span>{name}</span>
                    <span className="text-limegreen">
                        {` `}
                        {showGrowth(growth)}
                    </span>
                </span>
                <span className="text-xs text-black">{` `}</span>
            </span>
            <i className="text-darkslategray text-3xs">
                <span>(YOY){` `}</span>
            </i>
        </>
    );
};

const Slide = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    const { referenceTopic, type, topicsByVolume, topicsByGrowth } =
        slideContent;

    console.log({ showFull });

    const maxCount = 12;

    const minCount = 3;

    const totalCount = topicsByVolume.length;

    const [displayCount, setDisplayCount] = useState(
        showFull ? maxCount : minCount,
    );

    const handleShowAll = () => setDisplayCount(maxCount);
    return (
        <div className="relative bg-white w-full flex flex-col items-center justify-start text-left text-[26px] text-black font-poppins">
            <div className="flex flex-col items-center justify-start pt-0 px-0 pb-[34px]">
                <div className="w-[1174px] flex flex-row items-center justify-start pt-[60px] px-0 pb-[25px] box-border">
                    <div className="relative leading-[39px] capitalize font-semibold inline-block w-[1174px] shrink-0">
                        <span>{referenceTopic}</span>
                        <span className="text-eyvablue"></span>
                        <span>{` + `}</span>
                        <span className="text-eyvablue">{type}</span>
                    </div>
                </div>
                <div className="w-[1170px] flex flex-row flex-wrap items-start justify-start gap-[10px] text-lg">
                    {topicsByVolume.slice(0, displayCount).map((topic, i) => (
                        <Card
                            name={topic.name}
                            value={topic.value}
                            growth={topic.growth}
                            share={topic.share}
                            top={topic.top}
                            key={i}
                            index={i}
                        />
                    ))}
                </div>
                <div className="flex flex-row items-start justify-start py-[30px] px-0">
                    <ShowAllButton
                        displayCount={displayCount}
                        maxCount={maxCount}
                        handleShowAll={handleShowAll}
                        totalCount={totalCount}
                    />
                </div>
                <div className="bg-whitesmoke-100 flex flex-col items-start justify-start py-6 px-4 gap-[13px] text-xl">
                    <div className="rounded-md w-[1138px] overflow-hidden flex flex-row items-center justify-start">
                        <div className="relative leading-[30px] font-medium inline-block w-[1138px] shrink-0">
                            Most Growing {type} 🔥
                        </div>
                    </div>
                    <div className="relative leading-[35px] inline-block w-[1134px] text-base">
                        {topicsByGrowth.slice(0, 10).map((topic, i, arr) => (
                            <>
                                <GrowingTopic
                                    name={topic.name}
                                    growth={topic.growth}
                                    key={i}
                                />
                                {i !== arr.length - 1 && (
                                    <span className="text-xs">{`, `}</span>
                                )}
                            </>
                        ))}
                    </div>
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </div>
    );
};

export default Slide;
