import React from "react";
import Footer from "../_generic/Footer";
import ReactEcharts from "echarts-for-react";
import { Layout } from "../_generic/Layout";
import { getVerticalBarOptions } from "../_generic/chartOptions";
import { RightSideImageLayout } from "../_generic/RightSideImageLayout";

const getOptions = ({ data, lang = "en" }) => {
    // Extract dates for the x-axis from one of the time series

    const options = {
        animation: false,

        grid: {
            containLabel: true,
            bottom: 0,
        },

        xAxis: {
            type: "category",
            data: data.map((item) => item.attr),
            axisLabel: {
                interval: 0,
                rotate: 45, //If the label names are too long you can manage this by rotating the label.
            },
        },
        yAxis: {
            type: "value",
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        series: [
            {
                data: data.map((item) => item.value),
                itemStyle: { color: "#E393FF" },
                type: "bar",
                label: {
                    show: true,
                    position: "top",
                },
            },
        ],
    };

    return options;
};
export const Brands = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        imageURL = "https://res.cloudinary.com/djgzit41g/image/upload/v1701935339/ICE/woman_light_eye_cxzqph.png",
        data = [],
        lastDataUpdate = "ERROR",
    } = slideContent;

    return (
        <Layout showFull={showFull} className="relative">
            <p className="absolute top-12 right-10 text-sm z-10 text-gray-800">
                Last Data Update: {lastDataUpdate}
            </p>
            <RightSideImageLayout
                imageURL={imageURL}
                imageShadowColor="#D2AFFF"
            >
                <h1 className="text-black text-17xl font-semibold leading-[54px]">
                    {h1}
                </h1>
                <ReactEcharts
                    option={getVerticalBarOptions({
                        data,
                        rotateLabel: true,
                    })}
                    opts={{ renderer: "svg" }}
                    style={{ width: "650px", height: "440px" }}
                />
            </RightSideImageLayout>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default Brands;
