import React from "react";
import Footer from "../_generic/Footer";
import ReactEcharts from "echarts-for-react";
import { TwoColumnLayout } from "../_generic/TwoColumnLayout";
import { Layout } from "../_generic/Layout";
import {
    getHorizontalBarOptions,
    getVerticalBarOptions,
} from "../_generic/chartOptions";

export const TopSizes = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        blocks = [],
        title = "ERROR",
        lastDataUpdate = "ERROR",
        subTitle = "ERROR",
    } = slideContent;
    return (
        <Layout showFull={showFull} className="relative">
            <p className="absolute top-12 right-10 text-sm z-10 text-gray-800">
                Last Data Update: {lastDataUpdate}
            </p>
            <TwoColumnLayout
                title={
                    <div className="space-y-2">
                        <h1 className="text-black text-17xl font-semibold leading-[54px]">
                            {title}
                        </h1>
                        <h2 className="text-gray-500 text-sm">{subTitle}</h2>
                    </div>
                }
                color="#E7DEFA"
                leftSide={
                    <div className="space-y-5">
                        <h1 className="text-black text-lg font-semibold uppercase">
                            {blocks[0].h1}
                        </h1>
                        <div>
                            <ReactEcharts
                                option={getVerticalBarOptions({
                                    data: blocks[0].data,
                                    color: "#484CBC",
                                })}
                                opts={{ renderer: "svg" }}
                                style={{ width: "560px", height: "350px" }}
                            />
                            <p className="italic text-xs text-[#3A3A3A]">
                                {blocks[0].note}
                            </p>
                        </div>
                    </div>
                }
                rightSide={
                    <div className="space-y-5">
                        <h1 className="text-black text-lg font-semibold uppercase">
                            {blocks[1].h1}
                        </h1>
                        <div>
                            <ReactEcharts
                                option={getVerticalBarOptions({
                                    data: blocks[1].data,
                                    color: "#9D48BC",
                                })}
                                opts={{ renderer: "svg" }}
                                style={{ width: "560px", height: "350px" }}
                            />
                            <p className="italic text-xs text-[#3A3A3A]">
                                {blocks[1].note}
                            </p>
                        </div>
                    </div>
                }
            />
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TopSizes;
