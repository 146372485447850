import React from "react";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import { formatNumber } from "../../../utils/formatNumber";
import { APP_URL } from "../../../utils/constants";

export const DataRoom = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let { h1 = "ERROR", products = [] } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="min-h-[690px] px-10 py-8 space-y-6">
                <h1 className="text-black text-17xl font-semibold leading-[54px]">
                    {h1}
                </h1>
                <div className="grid grid-cols-3 gap-4 text-black">
                    {products.map((product, index) => (
                        <div
                            key={index}
                            className="border relative p-6 rounded-md border-gray-300 space-y-10 text-center mx-auto truncate w-full flex flex-col justify-between"
                        >
                            <div className="space-y-5">
                                <div>
                                    <div className="absolute top-5 left-5 rounded-full bg-[#F19D38] w-12 h-12 text-base font-semibold flex justify-center items-center">
                                        #{index + 1}
                                    </div>
                                    <div className="absolute top-5 right-5">
                                        <a
                                            href={product.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-xs px-3.5 font-medium py-1 bg-gray-200 text-eyvablue rounded-full"
                                        >
                                            Amazon link
                                        </a>
                                    </div>
                                </div>
                                <img
                                    src={product.imgURL}
                                    className="w-48 h-48 object-contain mx-auto"
                                />
                                <div className="font-medium space-y-2">
                                    <p className="whitespace-break-spaces line-clamp-2 text-base">
                                        {product.title}
                                    </p>
                                </div>
                            </div>
                            <div className="space-y-3 text-black text-sm text-left">
                                <p>
                                    <span className="text-gray-600 font-medium">
                                        Rating
                                    </span>
                                    : {product.rating}
                                </p>
                                <p>
                                    <span className="text-gray-600 font-medium">
                                        No of Reviews
                                    </span>
                                    : {formatNumber(product.reviews)}
                                </p>
                                <p>
                                    <span className="text-gray-600 font-medium">
                                        Price
                                    </span>
                                    : {product.price}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default DataRoom;
