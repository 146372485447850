import React from "react";
import { useUserData } from "../../stores/reportStore";
import Loading from "./Loading";
import { APP_URL } from "../../utils/constants";
import { createSearchParams } from "react-router-dom";

export const RequireAuth = ({ children }) => {
    const { userLoading, isLoggedIn, userError } = useUserData();
    console.log({ userError, userLoading, isLoggedIn });
    return (
        <>
            {userLoading ? (
                <Loading />
            ) : userError?.response?.status === 401 ? (
                <div className="">
                    <div
                        className={
                            "bg-white -z-1 pointer-events-none absolute top-0 left-0 bottom-0 right-0 m-auto w-full h-screen bg-cover bg-center bg-fixed bg-[url('/src/assets/images/bg-401.png')]"
                        }
                    ></div>
                    <div className="relative flex flex-col items-center justify-center w-full space-y-20 h-screen">
                        <img
                            className="absolute w-[180px] h-[40.39px] top-[20%]"
                            alt=""
                            src="/eyva_logo.svg"
                        />
                        <div className="text-center space-y-4">
                            <h1 className="text-2xl font-semibold leading-none">
                                Login Required!
                            </h1>
                            <p className="text-gray-900 text-sm">
                                In order to view the report, please log in to
                                your eyva account.{" "}
                            </p>
                            <button
                                className="px-4 py-2.5 text-white bg-blue-600 rounded-md"
                                onClick={() => {
                                    window.open(
                                        `${APP_URL}login?${createSearchParams({
                                            from: location.href,
                                        }).toString()}`,
                                    );
                                }}
                            >
                                Log in and read the report
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <>{children}</>
            )}
        </>
    );
};
