import React from "react";
import { flexRender } from "@tanstack/react-table";
import { classNames } from "../../../utils/classNames";
function range(start, end) {
    return Array.from({ length: end - start + 1 }, (_, i) => i);
}
export const Table = ({ table, minRows = 8, visibleRows, className }) => {
    const { rows } = table.getRowModel();

    return (
        <table className=" w-full">
            <thead className={``}>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                            return (
                                <th
                                    className={classNames(
                                        header.column.getCanSort()
                                            ? "cursor-pointer select-none "
                                            : "",

                                        header.column.columnDef?.meta
                                            ?.headerClassName,
                                        header.column.columnDef?.meta
                                            ?.className,
                                        header.column.columnDef?.meta
                                            ?.disablePadding
                                            ? ""
                                            : "px-4 py-3",
                                        "!border-x-0 font-medium",
                                    )}
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    style={{ width: header.getSize() }}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </th>
                            );
                        })}
                    </tr>
                ))}
            </thead>
            <tbody>
                {rows.map((row, index) => {
                    return (
                        <tr
                            key={row.id}
                            className={classNames(
                                `py-3 text-center text-sm font-normal text-primary-900`,
                                visibleRows &&
                                    visibleRows - 1 < index &&
                                    "pointer-events-none blur-sm",
                            )}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <td
                                    key={cell.id}
                                    className={classNames(
                                        ` border-t-2 border-gray-200`,
                                        cell.column.columnDef?.meta
                                            ?.disablePadding
                                            ? ""
                                            : "px-4 py-3",
                                        cell.column.columnDef?.meta
                                            ?.cellClassName,
                                        cell.column.columnDef?.meta?.className,
                                    )}
                                >
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                    )}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
