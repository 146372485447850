import * as React from "react";

const FiveStars = React.memo((props) => {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 38 31"
            fill="none"
            {...props}
        >
            <mask
                id="prefix__a"
                style={{
                    maskType: "luminance",
                }}
                maskUnits="userSpaceOnUse"
                x={13}
                y={0}
                width={12}
                height={11}
            >
                <path d="M13.136 0h11.728v10.874H13.136V0z" fill="#fff" />
            </mask>
            <g mask="url(#prefix__a)">
                <path
                    d="M16.195 6.817l-1.393 3.558a.3.3 0 00.424.374L19 8.71l3.774 2.04a.303.303 0 00.343-.039.302.302 0 00.08-.335l-1.392-3.557 2.945-2.632a.302.302 0 00-.2-.526h-3.724L19.274.246a.3.3 0 00-.548 0L17.174 3.66H13.45a.302.302 0 00-.282.193.303.303 0 00.08.333l2.946 2.631z"
                    fill="#F19D38"
                />
            </g>
            <mask
                id="prefix__b"
                style={{
                    maskType: "luminance",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={12}
                height={11}
            >
                <path d="M0 0h11.846v10.874H0V0z" fill="#fff" />
            </mask>
            <g mask="url(#prefix__b)">
                <path
                    d="M9.658 10.75a.302.302 0 00.343-.04.301.301 0 00.08-.335L8.69 6.817l2.945-2.632a.302.302 0 00-.2-.525H7.705L6.155.246a.3.3 0 00-.548 0L4.055 3.66H.33a.302.302 0 00-.282.193.302.302 0 00.082.333l2.945 2.632-1.393 3.557a.3.3 0 00.424.374L5.88 8.71l3.777 2.04z"
                    fill="#F19D38"
                />
            </g>
            <mask
                id="prefix__c"
                style={{
                    maskType: "luminance",
                }}
                maskUnits="userSpaceOnUse"
                x={26}
                y={0}
                width={12}
                height={11}
            >
                <path d="M26.154 0H38v10.874H26.154V0z" fill="#fff" />
            </mask>
            <g mask="url(#prefix__c)">
                <path
                    d="M37.95 3.853a.302.302 0 00-.281-.193h-3.723L32.394.246a.301.301 0 00-.549 0L30.294 3.66h-3.727a.302.302 0 00-.201.526l2.945 2.632-1.393 3.558a.3.3 0 00.424.374l3.778-2.041 3.774 2.04a.3.3 0 00.424-.374l-1.394-3.557 2.946-2.632a.304.304 0 00.08-.333z"
                    fill="#F19D38"
                />
            </g>
            <path
                d="M31.297 17.219h-3.723l-1.552-3.413a.3.3 0 00-.548 0l-1.555 3.413h-3.724a.301.301 0 00-.2.525l2.945 2.632-1.393 3.558a.3.3 0 00.424.374l3.777-2.043 3.774 2.043a.3.3 0 00.424-.374l-1.393-3.558 2.945-2.632a.301.301 0 00-.2-.525zM18.994 24.44l-3.121-1.987-.813-2.077 2.946-2.632a.3.3 0 00-.201-.525H14.08l-1.555-3.413a.3.3 0 00-.548 0l-1.552 3.413H6.703a.301.301 0 00-.201.525l2.945 2.632-1.393 3.558a.3.3 0 00.424.374l3.774-2.043.746.403.007 5.062a.3.3 0 00.446.264l1.705-.936 1.3 2.86a.301.301 0 00.419.14l2.008-1.105a.301.301 0 00.107-.428l-1.72-2.63 1.706-.937a.3.3 0 00.017-.518zm-2.298 1.085a.299.299 0 00-.107.428l1.72 2.629-1.44.791-1.298-2.86a.298.298 0 00-.274-.177c-.05 0-.1.013-.145.037l-1.545.848-.006-4.733v-.001l-.002-.768 4.642 2.955-1.545.85zM16.681 13.395a.302.302 0 00-.423.045l-.861 1.062a.301.301 0 10.467.378l.862-1.062a.3.3 0 00-.045-.423zM13.76 12.588v1.252a.3.3 0 10.603 0v-1.252a.3.3 0 10-.602 0zM17.01 16.256h1.379a.3.3 0 100-.601h-1.38a.3.3 0 100 .601zM7.522 23.072a.3.3 0 00-.364-.22l-1.331.328a.3.3 0 10.144.584l1.33-.328a.301.301 0 00.221-.364zM7.724 25.285l-.758.998a.3.3 0 00.24.483.302.302 0 00.24-.119l.758-.999a.3.3 0 10-.48-.363z"
                fill="#F19D38"
            />
            <path
                d="M7.15 21.044a.299.299 0 00.352-.239.301.301 0 00-.24-.352l-1.412-.267a.3.3 0 10-.112.591l1.412.267z"
                fill="#F19D38"
            />
        </svg>
    );
});
FiveStars.displayName = "FiveStars";

export default FiveStars;
