export const formatCurrency = (price = 0, currency) => {
    return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: currency || "EUR",
    }).format(price);
};

export const currencyMap = {
    EUR: "€",
    USD: "$",
    GBP: "£",
};

export const countryMap = {
    de: "EUR",
    us: "USD",
    uk: "GBP",
};

// replace all occurances EUR => "€",
export const mapCurrency = (val) => {
    Object.keys(currencyMap).forEach((key) => {
        if (val.includes(key)) {
            val = val.replaceAll(key, currencyMap[key]);
        }
    });
    return val;
};
