import React from "react";

import Footer from "../_generic/Footer";
import { CoverSlideLayout } from "../_generic/CoverSlideLayout";

const Cover = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
}) => {
    if (!slideContent) slideContent = {};
    const { h1 } = slideContent;

    return (
        <CoverSlideLayout
            imageURL={
                "https://veomzntynruzalwfhfmg.supabase.co/storage/v1/object/public/ice_public/AI%20Report_covers/IG_cover.jpg?t=2024-05-27T13%3A57%3A18.211Z"
            }
            footer={
                <Footer
                    slideName={slideName}
                    reportName={reportName}
                    reportVersion={reportVersion}
                    reportCreatedAt={reportCreatedAt}
                    reportLang={reportLang}
                    slideCounter={slideCounter}
                    withBackground={false}
                />
            }
            dark={false}
        >
            <div className="relative leading-[83px] text-[83px] font-medium text-white">
                {h1}
            </div>
        </CoverSlideLayout>
    );
};

export default Cover;
