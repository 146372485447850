import * as React from "react";

const UpTrend = React.memo((props) => {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 29 29"
            fill="none"
            {...props}
        >
            <g clipPath="url(#prefix__clip0_1079_734)">
                <path
                    d="M27.188 6.344v7.25a.906.906 0 01-.556.838.94.94 0 01-.997-.193l-2.979-2.99-6.604 6.615a.918.918 0 01-1.291 0l-3.886-3.896-7.51 7.521a.94.94 0 01-.646.261.963.963 0 01-.646-.26.918.918 0 010-1.292l8.156-8.156a.917.917 0 011.292 0l3.885 3.897 5.97-5.97-2.99-2.98a.952.952 0 01-.193-.996.917.917 0 01.838-.556h7.25a.906.906 0 01.907.907z"
                    fill="#F19D38"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_1079_734">
                    <path fill="#fff" d="M0 0h29v29H0z" />
                </clipPath>
            </defs>
        </svg>
    );
});

UpTrend.displayName = "UpTrend";

export default UpTrend;
