const ShowAllButton = ({
    displayCount,
    maxCount,
    handleShowAll,
    totalCount,
}) => {
    if (totalCount <= displayCount) return null;

    return (
        <>
            {displayCount < maxCount && (
                <button
                    onClick={handleShowAll}
                    className="cursor-pointer [border:none] py-[15px] px-4 bg-whitesmoke-200 w-[200px] flex flex-row items-center justify-center box-border hover:bg-thistle"
                >
                    <div className="relative text-base leading-[125%] font-medium font-poppins text-eyvablue text-center">
                        Show All
                    </div>
                </button>
            )}
        </>
    );
};

export default ShowAllButton;
