import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { getSlidesMapping } from "../../app/config/SlidesMapping";

import useStore, { useReportData } from "../../stores/reportStore";

const ReportView = () => {
    const { scale } = useStore();
    const { isLoading, error, reportData } = useReportData();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryShowFull = queryParams.get("show") === "full";

    if (!reportData) return null;

    const showFull = queryShowFull === "full";

    // Get the slides
    const slides = getSlidesMapping({ reportData, showFull });

    return (
        <>
            <div
                className="flex flex-col items-center"
                style={{
                    transform: `scale(${scale})`,
                    transformOrigin: "top",
                    WebkitFontSmoothing: "antialiased",
                }}
            >
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className="border border-black slide"
                        style={{
                            marginTop: "50px",
                            marginBottom: "50px",
                            boxShadow:
                                "0px 0px 15px 5px rgba(255, 255, 255, 0.10)",
                        }}
                    >
                        {slide}
                    </div>
                ))}
            </div>
        </>
    );
};

export default ReportView;
