import React, { useEffect, useMemo, useState } from "react";
import {
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import Footer from "../_generic/Footer";
import { Table } from "../_generic/Table";
import { formatCurrency } from "../../../utils/formatCurrency";
import ShowAllButton from "../_generic/ShowAllButton";
import { Layout } from "../_generic/Layout";
import { APP_URL } from "../../../utils/constants";
export const ProductList = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        h2 = "ERROR",
        lastDataUpdate = "ERROR",
        rows = [],
    } = slideContent;

    const [columnVisibility, setColumnVisibility] = useState({
        p_c_id: false,
        price_min: false,
        price_max: false,
        currency: false,
    });

    const columns = React.useMemo(
        () => [
            { accessorKey: "p_c_id" },
            { accessorKey: "price_min" },
            { accessorKey: "price_max" },
            { accessorKey: "currency" },
            {
                header: () => <p className="text-center">Photo</p>,
                accessorKey: "imgURL",
                size: 5,
                enableSorting: false,
                cell: (cell) => {
                    const img = cell.getValue();
                    return (
                        <img
                            className="-my-2 mx-auto border border-gray-50 text-center"
                            src={img ?? "/no-img-found.png"}
                            alt="product"
                            style={{
                                objectFit: "contain",
                                width: "40px",
                                height: "40px",
                            }}
                        />
                    );
                },
            },
            {
                header: "Product Name",
                accessorKey: "title",
                enableSorting: false,
                meta: { className: "text-left" },
                cell: ({ getValue, row }) => getValue(),
            },
            {
                accessorKey: "brand",
                header: "Brand",
                size: 90,
                enableSorting: false,
                meta: { className: "text-center" },
                cell: ({ getValue }) => getValue(),
            },
            {
                accessorKey: "sizes",
                header: "Sizes",
                size: 90,
                enableSorting: false,
                meta: { className: "text-center" },
                cell: ({ getValue }) => getValue().join(", "),
            },
            {
                id: "price",
                size: 90,
                header: "Price Range",
                cell: ({ getValue, row }) => {
                    const min = row.getValue("price_min");
                    const max = row.getValue("price_max");
                    const currency = row.getValue("currency");
                    return (
                        <p className="text-center">
                            {min === max
                                ? formatCurrency(min, currency)
                                : `${formatCurrency(
                                      min,
                                      currency,
                                  )} - ${formatCurrency(max, currency)}`}
                        </p>
                    );
                },
            },
            {
                id: "link",
                size: 10,
                header: "Link",
                cell: ({ getValue, row }) => {
                    const p_c_id = row.getValue("p_c_id");
                    return (
                        <a
                            href={`${APP_URL}product-details/${p_c_id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Link
                        </a>
                    );
                },
                meta: {
                    className: "text-center",
                    cellClassName: "text-eyvablue",
                },
            },
        ],
        [],
    );
    const maxCount = rows.length;

    const minCount = 7;

    const totalCount = rows.length;

    const [displayCount, setDisplayCount] = useState(minCount);

    useEffect(() => {
        if (showFull) {
            setDisplayCount(maxCount);
        }
    }, []);

    console.log({ maxCount, displayCount, minCount });

    const data = useMemo(
        () => rows.slice(0, displayCount),
        [rows, displayCount],
    );

    const table = useReactTable({
        data: data,
        columns,
        state: {
            columnVisibility,
        },
        onColumnVisibilityChange: setColumnVisibility,
        enableSortingRemoval: false,
        enableMultiSort: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (
        <Layout showFull={showFull} className="relative">
            <p className="absolute top-12 right-10 text-sm z-10 text-gray-800">
                Last Data Update: {lastDataUpdate}
            </p>
            <div className="p-10 space-y-8 w-full min-h-[690px]">
                <div>
                    <h1 className="text-black text-17xl font-semibold leading-[54px]">
                        {h1}
                    </h1>
                    <h2 className="text-gray-500 text-sm">{h2}</h2>
                </div>
                <div className="w-full text-[#11131A] font-normal text-sm">
                    <Table table={table} />
                </div>

                <div className="flex w-full justify-center">
                    <ShowAllButton
                        displayCount={displayCount}
                        maxCount={maxCount}
                        handleShowAll={() => setDisplayCount(totalCount)}
                        totalCount={totalCount}
                    ></ShowAllButton>
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default ProductList;
