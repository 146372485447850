import { useParams, useSearchParams } from "react-router-dom";

import { useReportData } from "../stores/reportStore";

import { getSlidesMapping } from "./config/SlidesMapping";

import Loading from "../components/ui/Loading";
import NoAccess from "../components/ui/NoAccess";

function Report() {
    const { slide } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const bpwd = searchParams.get("bpwd");

    const slideNo = parseInt(slide);

    const {
        reportData,
        isLoading,
        error: reportError,
    } = useReportData({ password: bpwd });

    // TODO: We must adapt this and make it more secure. Only if sup is provided correctly render the pages.

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : reportError?.response?.status === 401 ? (
                <NoAccess />
            ) : (
                <div className="flex flex-col items-center w-full min-h-screen text-white bg-black">
                    <div className="pdf-slide">
                        {
                            getSlidesMapping({ reportData, showFull: true })[
                                slideNo - 1
                            ]
                        }
                    </div>
                </div>
            )}
        </>
    );
}

export default Report;
