import React from "react";
import Footer from "../_generic/Footer";
import { RightSideImageLayout } from "../_generic/RightSideImageLayout";
import { Layout } from "../_generic/Layout";

export const DataScope = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        html = "ERROR",
        datePeriod = "ERROR",
        followers = "ERROR",
        newFollowers = "ERROR",
        engagement = "ERROR",
        avgMetrics = "ERROR",
        imageURL = "https://res.cloudinary.com/djgzit41g/image/upload/v1701935339/ICE/woman_light_eye_cxzqph.png",
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <RightSideImageLayout
                imageURL={imageURL}
                imageShadowColor="#43B2D8"
            >
                <div className="space-y-14 flex flex-col h-full justify-center">
                    <div className="space-y-5">
                        <h1 className="text-black text-17xl font-semibold leading-[54px]">
                            {h1}
                        </h1>
                        <div
                            className="text-sm text-gray-600"
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                    </div>

                    <div className="space-y-5 text-gray-600 text-sm">
                        <p>
                            <span className="font-semibold text-black">
                                Date period
                            </span>
                            : {datePeriod}
                        </p>
                        <p>
                            <span className="font-semibold text-black">
                                Total Followers
                            </span>
                            : {followers}
                        </p>
                        <p>
                            <span className="font-semibold text-black">
                                Followers Change
                            </span>
                            : {newFollowers}
                        </p>
                        <p>
                            <span className="font-semibold text-black">
                                Engagement rate / E.R
                            </span>
                            : {engagement}
                        </p>
                        <p>
                            <span className="font-semibold text-black">
                                Avg. Likes, Avg. Comments and Avg. Reel Views.
                            </span>
                            : {avgMetrics}
                        </p>
                    </div>
                </div>
            </RightSideImageLayout>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default DataScope;
