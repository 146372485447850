import React from "react";
import Footer from "../_generic/Footer";
import { RightSideImageLayout } from "../_generic/RightSideImageLayout";
import { Layout } from "../_generic/Layout";
import { HeartIcon } from "@heroicons/react/outline";
import { formatNumber } from "../../../utils/formatNumber";
import { PostCard } from "./TopPosts";
import { classNames } from "../../../utils/classNames";

export const Card = ({ imgURL, link, usernames, likes }) => {
    return (
        <div className="flex items-center space-x-4">
            <div className="relative shrink-0 grow-0">
                <img
                    src={imgURL}
                    className="h-36 w-36 object-contain rounded-md"
                ></img>
                <a
                    className="absolute bottom-3 text-xs font-medium -translate-x-1/2 left-1/2 whitespace-nowrap text-eyvablue bg-white rounded-full px-3 py-1"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                >
                    View Post
                </a>
            </div>
            <div className="flex flex-col justify-between h-full py-3 text-sm">
                <p className="font-medium text-gray-500">
                    <span className="text-[#37477C]">@{usernames[0]}</span> and{" "}
                    <span className="text-[#37477C]">@{usernames[1]}</span>
                </p>
                <div className="flex space-x-1 items-center font-medium text-[#37477C]">
                    <HeartIcon className="h-4 w-4" />
                    <p className="">{formatNumber(likes)}</p>
                </div>
            </div>
        </div>
    );
};

export const BrandOverview = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        profilePicture = "/no-img-found.png",
        username = "ERROR",
        brand = "ERROR",
        tagline = "ERROR",
        followers = "ERROR",
        datePeriod = "ERROR",
        newFollowers = "ERROR",
        engagement = "ERROR",
        newPosts = "ERROR",
        collaborationNote = "ERROR",
        collaboration = [],
        posts = [],
        adsNote = "ERROR",
        ads = [],
        hashtagsNote = "ERROR",
        hashtags = [],
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="p-12 text-black w-full divide-y border-gray-300">
                <div className="space-y-14 pb-12">
                    <div className="shadow-lg rounded-md w-full text-center p-16 ">
                        <div className="max-w-[60%] mx-auto space-y-10">
                            <div className="space-y-6">
                                <img
                                    src={profilePicture}
                                    className="w-20 h-20 shadow rounded-full mx-auto object-contain"
                                />
                                <div className="space-y-3">
                                    <p className="text-xl font-semibold leading-none">
                                        {brand}
                                    </p>
                                    <p className="text-base text-[#D426A4] leading-none">
                                        @{username}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        {tagline}
                                    </p>
                                </div>
                            </div>
                            <div className="bg-gray-100 rounded-md p-2.5 px-16 w-fit mx-auto">
                                <p className="text-xl font-semibold">
                                    {formatNumber(followers)}
                                </p>
                                <p className="text-sm text-gray-500">
                                    Total number of followers
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full py-5 rounded-md text-center bg-[#F7EDFF] text-sm ">
                        {datePeriod}
                    </div>
                    <div className=" grid grid-cols-3 gap-6 max-w-[60%] mx-auto text-center">
                        <div className="space-y-2">
                            <div
                                className={classNames(
                                    "bg-[#EAF9F5] rounded-md py-5 text-lg font-semibold ",
                                    newFollowers > 0
                                        ? "text-green-600"
                                        : newFollowers < 0
                                        ? "text-red-600"
                                        : "",
                                )}
                            >
                                {newFollowers > 0 && "+"}
                                {newFollowers == null
                                    ? "N/A"
                                    : `${formatNumber(newFollowers)}`}
                            </div>
                            <p className="font-medium text-sm text-black">
                                Followers Change
                            </p>
                        </div>
                        <div className="space-y-2">
                            <div
                                className={classNames(
                                    "bg-[#EAF9F5] rounded-md py-5 text-lg font-semibold ",
                                    engagement > 0 && "text-green-600",
                                )}
                            >
                                {`${Math.round(engagement * 100) / 100}%`}
                            </div>
                            <p className="font-medium text-sm text-black">
                                Engagement Rate (E.R.)
                            </p>
                        </div>
                        <div className="space-y-2">
                            <div
                                className={classNames(
                                    "bg-[#EAF9F5] rounded-md py-5 text-lg font-semibold ",
                                    newPosts > 0 && "text-green-600",
                                )}
                            >
                                {newPosts > 0 && "+"}
                                {newPosts}
                            </div>
                            <p className="font-medium text-sm text-black">
                                New Posts
                            </p>
                        </div>
                    </div>
                </div>
                {posts.length > 0 ? (
                    <>
                        <div className="space-y-7 py-12">
                            <h2 className="text-xl font-medium">
                                Collaborations
                            </h2>
                            {collaboration.length > 0 ? (
                                <>
                                    <p className="text-gray-500 text-sm !mt-0">
                                        {collaborationNote}
                                    </p>
                                    <div className="grid grid-cols-3 gap-5">
                                        {collaboration.map((collab, i) => (
                                            <Card
                                                key={i}
                                                imgURL={collab.imgURL}
                                                likes={collab.likes}
                                                usernames={[
                                                    username,
                                                    collab.username,
                                                ]}
                                                link={collab.link}
                                            />
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div className="max-w-md text-center mx-auto space-y-3">
                                    <img
                                        src="/no-data.svg"
                                        className="w-20 h-20 mx-auto"
                                    ></img>
                                    <p className="text-sm text-gray-500">
                                        In period from {datePeriod} this brand
                                        had in total of{" "}
                                        <span className="text-eyvablue">
                                            0 collaborations
                                        </span>
                                        .
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="space-y-7 py-12">
                            <h2 className="text-xl font-medium">
                                Top posts based on likes
                            </h2>
                            <div className="grid grid-cols-4 gap-4 text-black">
                                {posts.map((post, index) => (
                                    <PostCard key={index} {...post} />
                                ))}
                            </div>
                        </div>
                        <div className="space-y-7 py-12">
                            <div>
                                <h2 className="text-xl font-medium">Ads</h2>
                            </div>
                            {ads.length > 0 ? (
                                <>
                                    <p className="text-gray-500 text-sm !mt-0">
                                        {adsNote}
                                    </p>
                                    <div className="grid grid-cols-3 gap-5">
                                        {ads.map((ad, i) => (
                                            <Card
                                                key={i}
                                                imgURL={ad.imgURL}
                                                likes={ad.likes}
                                                usernames={[
                                                    username,
                                                    ad.username,
                                                ]}
                                                link={ad.link}
                                            />
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div className="max-w-md text-center mx-auto space-y-3">
                                    <img
                                        src="/no-data.svg"
                                        className="w-20 h-20 mx-auto"
                                    ></img>
                                    <p className="text-sm text-gray-500">
                                        In period from {datePeriod} this brand
                                        had in total of{" "}
                                        <span className="text-eyvablue">
                                            0 ads running
                                        </span>
                                        .
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="space-y-7 py-12">
                            <div>
                                <h2 className="text-xl font-medium">
                                    Hashtags
                                </h2>
                            </div>
                            {hashtags.length > 0 ? (
                                <>
                                    <p className="text-gray-500 text-sm !mt-0">
                                        {hashtagsNote}
                                    </p>
                                    <div className="flex items-center -m-1 flex-wrap">
                                        {hashtags.map((hashtag, i) => (
                                            <div
                                                key={i}
                                                className="text-sm text-[#37477C] bg-[#F1FCEA] m-1 px-3 py-2 rounded-md"
                                            >
                                                {hashtag.hashtag} (
                                                {hashtag.count})
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div className="max-w-md text-center mx-auto space-y-3">
                                    <img
                                        src="/no-data.svg"
                                        className="w-20 h-20 mx-auto"
                                    ></img>
                                    <p className="text-sm text-gray-500">
                                        In period from {datePeriod} this brand
                                        did not use any hashtags.
                                    </p>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="max-w-md text-center mx-auto space-y-3 py-12">
                        <img
                            src="/no-data.svg"
                            className="w-20 h-20 mx-auto"
                        ></img>
                        <p className="text-sm text-gray-500">
                            In period from {datePeriod} this brand had in total
                            of no new posts.
                        </p>
                    </div>
                )}
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default BrandOverview;
