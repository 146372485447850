import React from "react";
import Footer from "../_generic/Footer";
import { RightSideImageLayout } from "../_generic/RightSideImageLayout";
import { Layout } from "../_generic/Layout";
import { formatNumber } from "../../../utils/formatNumber";
import { formatCurrency, countryMap } from "../../../utils/formatCurrency";
import { StarIcon } from "@heroicons/react/solid";
import FiveStars from "../../icons/FiveStars";
import PriceTag from "../../icons/PriceTag";
import UpTrend from "../../icons/UpTrend";

export const Overview = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        category = "ERROR",
        subCategory = "ERROR",
        rating = "ERROR",
        reviews = "ERROR",
        price = "ERROR",
        sales = "ERROR",
        country = "ERROR",
        imageURL = "https://res.cloudinary.com/djgzit41g/image/upload/v1701935339/ICE/woman_light_eye_cxzqph.png",
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <RightSideImageLayout
                imageURL={imageURL}
                imageShadowColor="#FFDFB9"
            >
                <div className="space-y-14 flex flex-col h-full justify-center">
                    <div className="space-y-5">
                        <h1 className="text-black text-17xl font-semibold leading-[54px]">
                            {h1}
                        </h1>
                        <h2 className="text-black text-lg ">
                            {category} {">"}{" "}
                            <span className="text-eyvablue">{subCategory}</span>
                        </h2>
                    </div>

                    <div className="space-y-5  text-sm font-medium text-gray-600">
                        <div className="flex items-center space-x-2 p-5 rounded-md bg-gray-100 ">
                            <StarIcon className="h-6 w-6 text-[#F19D38]" />
                            <p>
                                <span className="font-semibold text-black">
                                    Avg. Rating
                                </span>
                                : {formatNumber(Math.round(rating * 100) / 100)}
                            </p>
                        </div>
                        <div className="flex items-center space-x-2 p-5 rounded-md bg-gray-100">
                            <FiveStars className="h-6 w-6 " />
                            <p>
                                <span className="font-semibold text-black">
                                    Avg. Reviews
                                </span>
                                : {formatNumber(parseInt(reviews))}
                            </p>
                        </div>
                        <div className="flex items-center space-x-2 p-5 rounded-md bg-gray-100">
                            <PriceTag className="h-6 w-6 " />
                            <p className="">
                                <span className="font-semibold text-black">
                                    Avg Price
                                </span>
                                :{" "}
                                {formatCurrency(
                                    Math.round(price * 100) / 100,
                                    countryMap[country],
                                )}
                            </p>
                        </div>
                        <div className="flex items-center space-x-2 p-5 rounded-md bg-gray-100">
                            <UpTrend className="h-6 w-6 " />
                            <p>
                                <span className="font-semibold text-black">
                                    Avg. Estimated Sales
                                </span>
                                :{" "}
                                {sales
                                    ? formatNumber(
                                          parseInt(sales),
                                          countryMap[country],
                                      )
                                    : "-"}
                            </p>
                        </div>
                    </div>
                </div>
            </RightSideImageLayout>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default Overview;
