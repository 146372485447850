import React, { useEffect, useMemo, useState } from "react";
import {
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import Footer from "../_generic/Footer";
import { Table } from "../_generic/Table";
import { ArrowUpIcon, ArrowDownIcon } from "@radix-ui/react-icons";
import ShowAllButton from "../_generic/ShowAllButton";
import { Layout } from "../_generic/Layout";
import { APP_URL } from "../../../utils/constants";
import { classNames } from "../../../utils/classNames";
import { formatNumber } from "../../../utils/formatNumber";
export const ProductList = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let { h1 = "ERROR", h2 = "ERROR", note, rows = [] } = slideContent;

    const [columnVisibility, setColumnVisibility] = useState({
        p_c_id: false,
        eyva_rank_change: false,
    });

    const columns = React.useMemo(
        () => [
            { accessorKey: "p_c_id" },
            { accessorKey: "eyva_rank_change" },
            {
                id: "srno",
                size: 10,
                header: () => <p className="text-center">#</p>,
                cell: (props) => {
                    const i =
                        props?.table
                            ?.getSortedRowModel()
                            ?.flatRows?.indexOf(props?.row) + 1;
                    return <>{i}</>;
                },
            },
            {
                header: () => <p className="text-center">Photo</p>,
                accessorKey: "imgURL",
                size: 70,
                enableSorting: false,
                cell: (cell) => {
                    const img = cell.getValue();
                    return (
                        <img
                            className="-my-2 mx-auto border border-gray-50 text-center"
                            src={img ?? "/no-img-found.png"}
                            alt="product"
                            style={{
                                objectFit: "contain",
                                width: "40px",
                                height: "40px",
                            }}
                        />
                    );
                },
            },
            {
                header: "Product Name",
                accessorKey: "title",
                enableSorting: false,
                meta: { className: "text-left" },
                cell: ({ getValue, row }) => getValue(),
            },
            {
                accessorKey: "brand",
                header: "Brand",
                size: 120,
                enableSorting: false,
                meta: { className: "text-left" },
                cell: ({ getValue }) => getValue(),
            },
            {
                header: () => <p className="">Category Rank</p>,
                size: 90,
                accessorKey: "eyva_rank",
                meta: {
                    className: "text-left bg-primary-50",
                    headerClassName: "text-left whitespace-nowrap",
                },
                cell: ({ getValue, row }) => {
                    const growth = row.getValue("eyva_rank_change");
                    return (
                        <div className="ml-3 flex items-center space-x-1">
                            <p>{getValue()}</p>
                            <div
                                className={classNames(
                                    growth > 0
                                        ? "text-green-700"
                                        : growth < 0
                                        ? "text-red-700"
                                        : "",
                                    "flex items-center space-x-0.5 text-xs",
                                )}
                            >
                                {growth > 0 ? (
                                    <ArrowUpIcon className="h-2 w-2" />
                                ) : growth < 0 ? (
                                    <ArrowDownIcon className="h-2 w-2" />
                                ) : null}
                                {growth == null ? (
                                    <>&#x2015;</>
                                ) : (
                                    <p>{formatNumber(Math.abs(growth))}</p>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                id: "link",
                size: 10,
                header: "Link",
                cell: ({ getValue, row }) => {
                    const p_c_id = row.getValue("p_c_id");
                    return (
                        <a
                            href={`${APP_URL}product-details/${p_c_id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Link
                        </a>
                    );
                },
                meta: {
                    className: "text-center",
                    cellClassName: "text-eyvablue",
                },
            },
        ],
        [],
    );
    const maxCount = rows.length;

    const minCount = 7;

    const totalCount = rows.length;

    const [displayCount, setDisplayCount] = useState(minCount);

    useEffect(() => {
        if (showFull) {
            setDisplayCount(maxCount);
        }
    }, []);

    console.log({ maxCount, displayCount, minCount });

    const data = useMemo(
        () => rows.slice(0, displayCount),
        [rows, displayCount],
    );

    const table = useReactTable({
        data: data,
        columns,
        state: {
            columnVisibility,
        },
        defaultColumn: {
            size: 400, //starting column size
            minSize: 0, //enforced during column resizing
            maxSize: 500, //enforced during column resizing
        },
        onColumnVisibilityChange: setColumnVisibility,
        enableSortingRemoval: false,
        enableMultiSort: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (
        <Layout showFull={showFull}>
            <div className="p-10 space-y-8 w-full min-h-[690px]">
                <div>
                    <h1 className="text-black text-17xl font-semibold leading-[54px]">
                        {h1}
                    </h1>
                    <h2 className="text-gray-500 text-sm">{h2}</h2>
                    {note && <h2 className="text-eyvablue text-sm">{note}</h2>}
                </div>
                <div className="w-full text-[#11131A] font-normal text-sm">
                    <Table table={table} />
                </div>

                <div className="flex w-full justify-center">
                    <ShowAllButton
                        displayCount={displayCount}
                        maxCount={maxCount}
                        handleShowAll={() => setDisplayCount(totalCount)}
                        totalCount={totalCount}
                    ></ShowAllButton>
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default ProductList;
