import React from "react";

import Footer from "../_generic/Footer";
import { CoverSlideLayout } from "../_generic/CoverSlideLayout";

const Cover = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
}) => {
    if (!slideContent) slideContent = {};
    const { h1, h2 } = slideContent;

    return (
        <CoverSlideLayout
            imageURL={
                "https://veomzntynruzalwfhfmg.supabase.co/storage/v1/object/public/ice_public/AI%20Report_covers/AM_Cover.png?t=2024-05-14T10%3A15%3A02.523Z"
            }
            footer={
                <Footer
                    slideName={slideName}
                    reportName={reportName}
                    reportVersion={reportVersion}
                    reportCreatedAt={reportCreatedAt}
                    reportLang={reportLang}
                    slideCounter={slideCounter}
                    withBackground={false}
                    dark
                />
            }
        >
            <div className="relative text-[90px] font-medium leading-none">
                {h1}
            </div>
            <b className="relative text-[30px] uppercase">{h2}</b>
        </CoverSlideLayout>
    );
};

export default Cover;
