import React from "react";

import { VeltCommentTool } from "@veltdev/react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
    HamburgerMenuIcon,
    DotsVerticalIcon,
    DotFilledIcon,
    CheckIcon,
    GearIcon,
    ChevronRightIcon,
    DotsHorizontalIcon,
} from "@radix-ui/react-icons";
import ToolTip from "../ToolTip";

import useStore, {
    useReportData,
    useUserData,
} from "../../../stores/reportStore";

const DropdownMenuButton = ({ isLoggedIn }) => {
    const { showComments, showCursors, setShowCursors, setShowComments } =
        useStore();

    const { isLoading, error, reportData } = useReportData();

    const reportPdfUrl = reportData?.reportPdfUrl;

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger>
                <ToolTip.Root>
                    <ToolTip.Trigger>
                        <button
                            className="hover:bg-blue3 inline-flex h-[35px] w-[35px] items-center justify-center rounded-full bg-white text-black outline-none focus:shadow-[0_0_0_2px]"
                            aria-label="Customise options"
                        >
                            <GearIcon />
                        </button>
                    </ToolTip.Trigger>
                    <ToolTip.Content>More Options</ToolTip.Content>
                </ToolTip.Root>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    className="min-w-[220px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
                    sideOffset={16}
                >
                    {isLoggedIn ? (
                        <>
                            <DropdownMenu.CheckboxItem
                                className="text-[13px] leading-none text-blue11 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-blue9 data-[highlighted]:text-blue1"
                                checked={showComments}
                                onCheckedChange={setShowComments}
                            >
                                <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                    <CheckIcon />
                                </DropdownMenu.ItemIndicator>
                                Show Comments
                            </DropdownMenu.CheckboxItem>

                            <DropdownMenu.CheckboxItem
                                className="text-[13px] leading-none text-blue11 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-blue9 data-[highlighted]:text-blue1"
                                checked={showCursors}
                                onCheckedChange={setShowCursors}
                            >
                                <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                    <CheckIcon />
                                </DropdownMenu.ItemIndicator>
                                Show Collaborator's Cursors
                            </DropdownMenu.CheckboxItem>
                            <DropdownMenu.Separator className="h-[1px] bg-blue6 m-[5px]" />
                        </>
                    ) : (
                        <>
                            <DropdownMenu.Item className="group text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1">
                                <a
                                    href="https://app.eyva.ai/login"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Login in to eyva 🚀
                                </a>
                            </DropdownMenu.Item>
                            <DropdownMenu.Separator className="h-[1px] bg-blue6 m-[5px]" />
                        </>
                    )}

                    <DropdownMenu.Sub>
                        <DropdownMenu.SubTrigger className="group text-[13px] leading-none text-blue11 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[state=open]:bg-blue4 data-[state=open]:text-blue11 data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-blue9 data-[highlighted]:text-blue1 data-[highlighted]:data-[state=open]:bg-blue9 data-[highlighted]:data-[state=open]:text-blue1">
                            Export
                            <div className="ml-auto pl-[20px] text-mauve11 group-data-[highlighted]:text-white group-data-[disabled]:text-mauve8">
                                <ChevronRightIcon />
                            </div>
                        </DropdownMenu.SubTrigger>
                        <DropdownMenu.Portal>
                            <DropdownMenu.SubContent
                                className="min-w-[220px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
                                sideOffset={2}
                                alignOffset={-5}
                            >
                                <DropdownMenu.Item className="group text-[13px] leading-none text-blue11 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-blue9 data-[highlighted]:text-blue1">
                                    <a
                                        href={reportPdfUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Save as PDF
                                    </a>
                                </DropdownMenu.Item>
                            </DropdownMenu.SubContent>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Sub>
                    {/* 
                    <DropdownMenu.Separator className="h-[1px] bg-blue6 m-[5px]" />
                    <DropdownMenu.Item className="group text-[13px] leading-none text-blue11 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-blue9 data-[highlighted]:text-blue1">
                        Give Feedback
                    </DropdownMenu.Item> */}

                    <DropdownMenu.Arrow className="fill-white" />
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

const DropdownMenuComponent = () => {
    const { userLoading, userError, isLoggedIn, userInfo } = useUserData();

    return (
        <VeltCommentTool>
            <div slot="button">
                <DropdownMenuButton isLoggedIn={isLoggedIn} />
            </div>
        </VeltCommentTool>
    );
};

export default DropdownMenuComponent;
