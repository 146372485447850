// 10000 => 10.000
export const formatNumber = (number = 0) => {
    return new Intl.NumberFormat("de-DE", { style: "decimal" }).format(number);
};

// 10000 => 10K
export const formatNumberCompact = (number = 0) => {
    return new Intl.NumberFormat("en-US", { notation: "compact" }).format(
        number,
    );
};
