import React from "react";
import Footer from "../_generic/Footer";
import ReactEcharts from "echarts-for-react";
import { TwoColumnLayout } from "../_generic/TwoColumnLayout";
import { Layout } from "../_generic/Layout";
import { getVerticalBarOptions } from "../_generic/chartOptions";

export const TopSizes = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        h2 = "ERROR",
        lastDataUpdate = "ERROR",
        charts = [],
    } = slideContent;

    return (
        <Layout showFull={showFull} className="relative">
            <p className="absolute top-12 right-10 text-sm z-10 text-gray-800">
                Last Data Update: {lastDataUpdate}
            </p>
            <TwoColumnLayout
                color="#F1FCEA"
                title={
                    <div>
                        <h1 className="text-black text-17xl font-semibold leading-[54px]">
                            {h1}
                        </h1>
                        <h2 className="text-gray-500 text-sm">{h2}</h2>
                    </div>
                }
                leftSide={
                    <div className="">
                        <h3 className="text-lg font-medium text-[#2515FF] uppercase">
                            {charts[0].title}
                        </h3>
                        <ReactEcharts
                            option={getVerticalBarOptions({
                                data: charts[0].data,
                            })}
                            opts={{ renderer: "svg" }}
                            style={{ width: "560px", height: "340px" }}
                        />
                        <p className="italic text-xs text-[#3A3A3A]">
                            {charts[0].note}
                        </p>
                    </div>
                }
                rightSide={
                    <div className="">
                        <h3 className="text-lg font-medium text-[#2515FF] uppercase">
                            {charts[1].title}
                        </h3>
                        <ReactEcharts
                            option={getVerticalBarOptions({
                                data: charts[1].data,
                                color: "#2515FF",
                            })}
                            opts={{ renderer: "svg" }}
                            style={{ width: "560px", height: "340px" }}
                        />
                        <p className="italic text-xs text-[#3A3A3A]">
                            {charts[1].note}
                        </p>
                    </div>
                }
            />
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TopSizes;
