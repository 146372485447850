import { create } from "zustand";
import { axios } from "../utils/helpers";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { createTrackedSelector } from "react-tracked";

const useStore = create((set) => ({
    password: "",
    passwordSubmitted: false,
    checking: false,
    scale: 1,
    showComments: true,
    showCursors: true,
    sup: "",

    // userInfo: {},
    // isLoggedIn: false,
    // setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn }),
    // setUserInfo: (userInfo) => set({ userInfo }),
    setShowComments: (showComments) => set({ showComments }),
    setShowCursors: (showCursors) => set({ showCursors }),
    // setLoading: (loading) => set({ loading }),
    setPassword: (password) => set({ password }),
    setPasswordSubmitted: (passwordSubmitted) => set({ passwordSubmitted }),
    // setError: (error) => set({ error }),
    setChecking: (checking) => set({ checking }),
    setScale: (scale) => set({ scale }),
    setSup: (sup) => set({ sup }),
}));

const useTrackedStore = createTrackedSelector(useStore);

export default useTrackedStore;

export function useReportData(options) {
    const password = options?.password;
    const { reportId } = useParams();

    // TODO: sup is the super password. Only need for the pdf creation tool
    // if the sup is provided then in any case respond with slide data.
    // this must be also done in the backend

    const {
        isLoading,
        error,
        data: reportData,
    } = useQuery({
        queryKey: ["reportData", reportId],
        queryFn: () =>
            axios
                .get(
                    `api/reports/report/${reportId}${
                        password ? `?password=${password}` : ""
                    }`,
                )
                .then((res) => res.data),
        retryOnMount: false,
        select: (data) => data?.data ?? {},
        onError: (error) => {},
        retry: (failureCount, error) => error.response.status !== 401, // dont retry if the error was caused by a 401 (unauthorized)
    });

    return {
        isLoading,
        error,
        reportPermissions: reportData?.permissions,
        reportData: reportData?.report,
        reportStatus: reportData?.status,
        pageTitle: reportData?.report?.reportName
            ? `${reportData?.report.reportName} (${
                  reportData?.report.reportCreatedAt?.split("T")?.[0]
              })`
            : "eyva ice",
    };
}

export function useUserData() {
    const { reportId } = useParams();
    const {
        isLoading: userLoading,
        error: userError,
        data: userData,
        isSuccess,
    } = useQuery({
        queryKey: ["userData", reportId],
        queryFn: () =>
            axios
                .get(`api/users/info?reportId=${reportId}`)
                .then((res) => res.data),
        enabled: !!reportId,
        select: (data) => data?.data ?? {},
        retryOnMount: false,
        retry: (failureCount, error) => error.response.status !== 401, // dont retry if the error was caused by a 401 (unauthorized)
    });

    return {
        userInfo: userData,
        userLoading: userLoading,
        userError,
        isLoggedIn: isSuccess,
    };
}
