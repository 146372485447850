import ReactEcharts from "echarts-for-react";

import Footer from "../_generic/Footer";

import { formatNumber, showGrowth } from "../../../utils/numbers";

const colors = {
    others: "#d1d5dc",
    reference: "#002aff",
};

const getOptions = ({ data, lang = "en" }) => {
    // Find the reference item
    const refItem = data.find((item) => item.ref);
    const maxValue = refItem
        ? refItem.value
        : Math.max(...data.map((item) => item.value));

    // Calculate relative values
    const relativeData = data.map((item) => ({
        ...item,
        relativeValue: item.value / maxValue,
    }));

    // Sort the data by relative value, keeping the reference item at the end
    relativeData.sort((a, b) => {
        if (a.ref) return 1;
        if (b.ref) return -1;
        return a.relativeValue - b.relativeValue;
    });

    // Find the largest value among non-reference topics
    const largestNonRefItem = relativeData
        .filter((item) => !item.ref)
        .reduce((prev, current) =>
            prev.value > current.value ? prev : current,
        );

    // Calculate the ratio
    const ratio = largestNonRefItem.value / refItem.value;

    console.log({ ratio });

    // Construct dynamic xAxisText
    let comparison;
    let ratioText;

    if (ratio > 1) {
        comparison = "greater";
        ratioText = `${ratio.toFixed(2)}x`;
    } else if (ratio < 1) {
        comparison = "less";
        ratioText = `${(1 / ratio).toFixed(2)}x`;
    } else {
        comparison = "equal";
        ratioText = "";
    }

    let xAxisText = `The monthly search volume (sv) for ${largestNonRefItem.topic} is \n${ratioText} ${comparison} than for ${refItem.topic}`;

    if (lang === "de") {
        if (ratio > 1) {
            comparison = "größer";
            ratioText = `um das ${ratio.toFixed(2)}-fache`;
        } else if (ratio < 1) {
            comparison = "geringer";
            ratioText = `um das ${(1 / ratio).toFixed(2)}-fache`;
        } else {
            comparison = "gleich";
            ratioText = "";
        }

        xAxisText = `Das monatliche Suchvolumen (sv) für ${largestNonRefItem.topic} ist \n${ratioText} ${comparison} als für ${refItem.topic}`;
    }

    return {
        animation: false,
        grid: {
            left: "-20px", // Adjust as needed
            right: "10px", // Adjust as needed
            top: "50px", // Adjust as needed
            bottom: "40px", // Adjust as needed
            containLabel: true,
        },
        xAxis: {
            type: "category",
            data: relativeData.map((item) => item.topic),
            name: xAxisText,
            nameLocation: "middle",
            nameGap: 30,
            axisTick: {
                show: false, // Hide the axis ticks
            },
            axisLabel: {
                fontFamily: "Arial", // Set the font family for x-axis labels
                fontStyle: "normal", // You can set it to 'italic' or 'normal'
                fontSize: 12, // Set the font size
                // You can add more styling properties as needed
            },
        },
        yAxis: {
            show: false,
        },
        series: [
            {
                data: relativeData.map((item) => ({
                    value: item.relativeValue, // This is the relative value
                    itemStyle: {
                        color: item.itemColor,
                    },
                    label: {
                        show: true,
                        position: "top",
                        formatter: function (params) {
                            // params.dataIndex gives the index of the data item
                            const actualValue =
                                relativeData[params.dataIndex].value; // Get the actual value from the original data array
                            const formattedValue = formatNumber(actualValue); // Format the number
                            const relativeValueText =
                                `${params.value.toFixed(1)}x` === "1.00x"
                                    ? "1x"
                                    : `${params.value.toFixed(2)}x`;
                            // return `{large|${relativeValueText}}\n({normal|sv = ${formattedValue}})`;
                            return `{normal|${formattedValue}} (sv)`;
                        },
                        rich: {
                            large: {
                                fontSize: 16,
                                lineHeight: 25,
                                align: "center",
                                fontFamily: "Verdana",
                            },
                            normal: {
                                fontSize: 10,
                                align: "center",
                                fontStyle: "italic",
                                fontFamily: "Verdana",
                            },
                        },
                    },
                })),
                type: "bar",
            },
        ],
    };
};

const Slide = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    const { paragraphs = [], data } = slideContent;

    for (const d of data) {
        if (d.ref) d.itemColor = colors.reference;
        else d.itemColor = colors.others;
    }

    console.log({ showFull });

    const { value: sv, yoy = null } = data.filter((d) => d.ref)[0];

    return (
        <div className="relative bg-white w-full flex flex-col items-center justify-start min-w-[1280px] max-w-[1280px] text-left text-[36px] text-black font-poppins">
            <div className="self-stretch flex flex-row items-start justify-center min-h-[690px]">
                <div className="flex-1 flex flex-row items-start justify-center p-[60px]">
                    <div className="flex-1 flex flex-col items-start justify-start py-0 pr-0 pl-[10px] box-border gap-[34px] min-w-[600px] max-w-[1000px]">
                        <div className="flex flex-row items-center justify-start w-full">
                            <div className="relative leading-[54px] font-semibold inline-block w-full shrink-0">
                                Topic Overview
                            </div>
                        </div>
                        <div className="flex flex-col items-start justify-center w-full text-darkslategray">
                            {/* <div className="relative leading-[27px] text-[16px] inline-block w-full scroll-container h-[400px] pr-5"> */}
                            <div
                                className={`relative leading-[27px] text-[16px] inline-block w-full ${
                                    showFull
                                        ? "overflow-visible"
                                        : "overflow-auto scroll-container h-[400px]"
                                } pr-5`}
                            >
                                {paragraphs.map((paragraph, i) => (
                                    <>
                                        <h1 className="font-semibold leading-[54px] text-[18px] text-eyvablue">
                                            {paragraph.header}
                                        </h1>
                                        <p
                                            key={i}
                                            className="leading-7 text-black mb-[20px] text-[16px]"
                                            dangerouslySetInnerHTML={{
                                                __html: paragraph.paragraph,
                                            }}
                                        ></p>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="self-stretch w-[640px] flex flex-col items-center justify-start py-2.5 px-[0px] box-border text-lg text-eyvablue">
                    <div className="self-stretch flex flex-col items-start justify-start pt-[60px] px-0 pb-px gap-[7px]">
                        <div className="flex flex-row items-start justify-start text-[16px]">
                            <div className="relative leading-[27px] font-medium">
                                <span className="uppercase">Google KPI</span>
                                <span className="lowercase">s</span>
                            </div>
                        </div>
                        <div className="w-[150px] flex flex-col items-start justify-start text-black">
                            <div className="relative inline-block w-[250px]">
                                <span className="font-medium">
                                    <span className="leading-[30px]">
                                        {formatNumber(sv)}
                                    </span>
                                </span>
                                <span className="leading-[35px] text-sm text-darkslategray">
                                    <span className="font-medium">
                                        <span className="text-xl">{` `}</span>
                                    </span>
                                    <i className="text-[12px]">
                                        (monthly search volume)
                                    </i>
                                </span>
                            </div>
                        </div>
                        <div className="w-[150px] flex flex-col items-start justify-start text-limegreen">
                            <div className="relative inline-block w-[200px]">
                                <span className="font-medium">
                                    <span className="leading-[30px]">
                                        {showGrowth(yoy)}
                                    </span>
                                </span>
                                <span className="leading-[35px] text-sm text-darkslategray">
                                    <span className="font-medium">
                                        <span className="text-[12px]">{` `}</span>
                                    </span>
                                    <i className="text-[12px]">(YoY growth)</i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-start self-stretch justify-start pt-[20px]">
                        <div className="relative w-[500px] h-[300px]">
                            <ReactEcharts
                                option={getOptions({ data })}
                                opts={{ renderer: "svg" }}
                                style={{ width: "500px", height: "300px" }}
                            ></ReactEcharts>
                        </div>
                    </div>
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </div>
    );
};

export default Slide;
