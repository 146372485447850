import React, { useState } from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";

import Footer from "../_generic/Footer";

import { formatNumber, showGrowth } from "../../../utils/numbers";

import ShowAllButton from "../_generic/ShowAllButton";

const Row = ({ idx, keyword, growthType, sv, yoy, trend }) => {
    return (
        <>
            <div className="flex flex-row items-start self-stretch justify-start h-11">
                <div className="self-stretch box-border w-[30px] flex flex-row items-center justify-center py-2.5 pr-2.5 pl-0 text-center text-[10px] border-t-[1px] border-solid border-gainsboro border-b-[1px]">
                    <div className="relative flex flex-row items-center justify-center">
                        <div className="relative rounded-[50%] bg-whitesmoke-100 w-[25px] h-[25px] z-[0]" />
                        <div className="absolute my-0 mx-[!important] top-[6px] left-[0.5px] leading-[125%] font-medium inline-block w-[25px] h-[18px] shrink-0 z-[1]">
                            {idx}
                        </div>
                    </div>
                </div>
                <div className="self-stretch box-border w-[470px] flex flex-row items-center justify-start p-2.5 border-t-[1px] border-solid border-gainsboro border-b-[1px]">
                    <img
                        className="relative self-stretch flex-1 hidden object-cover max-w-full max-h-full overflow-hidden"
                        alt=""
                        src="/product-photo@2x.png"
                    />
                    <div className="relative">{keyword}</div>
                </div>
                <div className="self-stretch box-border w-[175px] flex flex-row items-center justify-start p-2.5 border-t-[1px] border-solid border-gainsboro border-b-[1px]">
                    <div className="relative">{growthType}</div>
                </div>
                <div className="self-stretch bg-whitesmoke box-border w-[70px] flex flex-row items-center justify-center p-2.5 text-right text-royalblue border-t-[1px] border-solid border-gainsboro border-b-[1px]">
                    <div className="relative font-medium">
                        {formatNumber(sv)}
                    </div>
                </div>
                <div className="self-stretch box-border w-[70px] flex flex-row items-center justify-center p-2.5 text-right text-mediumseagreen border-t-[1px] border-solid border-gainsboro border-b-[1px]">
                    <div className="relative">{showGrowth(yoy)}</div>
                </div>
                <div className="self-stretch flex-1 flex flex-row items-center justify-end p-2.5 border-t-[1px] border-solid border-gainsboro border-b-[1px]">
                    <div className="bg-mistyrose w-[197px] h-[35px]">
                        <Sparklines
                            data={trend.map((t) => t.sv)}
                            width={190}
                            height={35}
                            margin={5}
                            min={0}
                        >
                            <SparklinesLine
                                color="blue"
                                style={{ fill: "none" }}
                            />
                        </Sparklines>
                    </div>
                </div>
            </div>
        </>
    );
};

const Slide = ({
    slideContent,
    slideName,
    reportName,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    const { h1, referenceTopic, rows } = slideContent;

    const maxCount = 100;

    const minCount = 10;

    const totalCount = rows.length;

    const [displayCount, setDisplayCount] = useState(
        showFull ? maxCount : minCount,
    );

    const handleShowAll = () => setDisplayCount(maxCount);

    const h2 = `Among all search terms for “${referenceTopic}” following search terms are standing out.`;

    return (
        <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start min-w-[1280px] max-w-[1280px] text-left text-17xl text-black font-poppins">
            <div className="self-stretch flex flex-col items-center justify-start pt-[60px] px-[60px] pb-[34px] box-border gap-[10px] min-h-[690px]">
                <div className="self-stretch flex flex-col items-start justify-end gap-[10px]">
                    <div className="flex flex-row items-center justify-start gap-[10px]">
                        <div className="relative font-semibold">{h1}</div>
                        <div className="relative font-semibold text-eyvablue">
                            {referenceTopic}
                        </div>
                    </div>
                    <div className="relative text-[18px] text-darkslategray">
                        {h2}
                    </div>
                </div>
                <div className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-2.5 text-base">
                    <div className="flex flex-row items-start self-stretch justify-start h-12">
                        <div className="self-stretch w-[30px] flex flex-row items-center justify-start py-2.5 pr-2.5 pl-0 box-border">
                            <div className="relative font-medium">Nr.</div>
                        </div>
                        <div className="self-stretch w-[470px] flex flex-row items-center justify-start p-2.5 box-border">
                            <div className="relative font-medium">Keyword</div>
                        </div>
                        <div className="self-stretch w-[175px] flex flex-row items-center justify-start p-2.5 box-border">
                            <div className="relative font-medium">
                                Trend Type
                            </div>
                        </div>
                        <div className="self-stretch w-[70px] flex flex-row items-center justify-center p-2.5 box-border text-right">
                            <div className="relative font-medium">SV</div>
                        </div>
                        <div className="self-stretch w-[70px] flex flex-row items-center justify-center p-2.5 box-border text-right">
                            <div className="relative font-medium">YoY</div>
                        </div>
                        <div className="self-stretch flex-1 flex flex-row items-center justify-end py-2.5 pr-12 pl-2.5 text-right">
                            <div className="relative font-medium">
                                3 Years Trend
                            </div>
                        </div>
                    </div>
                    {rows.slice(0, displayCount).map((row, i) => (
                        <Row
                            idx={i + 1}
                            keyword={row.keyword}
                            growthType={row.growthType}
                            sv={row.sv}
                            yoy={row.yoy}
                            trend={row.trend}
                            key={i}
                        />
                    ))}
                </div>
                <ShowAllButton
                    displayCount={displayCount}
                    maxCount={maxCount}
                    handleShowAll={handleShowAll}
                    totalCount={totalCount}
                />
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </div>
    );
};

export default Slide;
