import React from "react";
import Footer from "../_generic/Footer";
import ReactEcharts from "echarts-for-react";
import { TwoColumnLayout } from "../_generic/TwoColumnLayout";
import { Layout } from "../_generic/Layout";
import { getVerticalBarOptions } from "../_generic/chartOptions";
import { mapCurrency } from "../../../utils/formatCurrency";
import { PricingDistributionBar } from "../_generic/PricingDistributionBar";

export const TopSizesPricing = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let { blocks = [] } = slideContent;
    return (
        <Layout showFull={showFull}>
            <TwoColumnLayout
                color="#F9FCEA"
                leftSide={
                    <div className="space-y-14">
                        <h1 className="text-black text-17xl font-semibold leading-[54px]">
                            {blocks[0].h1}
                        </h1>
                        <div>
                            <h3 className="text-lg font-medium text-[#2515FF] uppercase">
                                {blocks[0].title}
                            </h3>
                            <ReactEcharts
                                option={getVerticalBarOptions({
                                    data: blocks[0].data,
                                    color: "#93C4A4",
                                })}
                                opts={{ renderer: "svg" }}
                                style={{ width: "560px", height: "340px" }}
                            />
                            <p className="italic text-xs text-[#3A3A3A]">
                                {blocks[0]?.note}
                            </p>
                        </div>
                    </div>
                }
                rightSide={
                    <div className="space-y-14">
                        <h1 className="text-black text-17xl font-semibold leading-[54px]">
                            {blocks[1].h1}
                        </h1>
                        <div className="space-y-8">
                            <h3 className="text-lg font-medium text-[#2515FF] uppercase">
                                {blocks[1].title}
                            </h3>

                            <div className="space-y-4">
                                {blocks[1].data.map((item, index) => (
                                    <PricingDistributionBar
                                        key={index}
                                        label={mapCurrency(item.attr)}
                                        value={item.value}
                                    />
                                ))}
                            </div>

                            <p className="italic text-xs text-[#3A3A3A]">
                                {blocks[1]?.note}
                            </p>
                        </div>
                    </div>
                }
            />
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TopSizesPricing;
