import { VeltPresence, useVeltClient } from "@veltdev/react";
import * as Avatar from "@radix-ui/react-avatar";
import { useState, useEffect } from "react";

import useStore, { useUserData } from "../../../stores/reportStore";

import { mapEyvaUserToVeltUser } from "../../../utils/helpers";

// Here we want to show the current logged in user's avatar as well as other users who are currently viewing the report.
// Velt's default Presence only shows the other user
// So we need to create our own custom component to show the current user's avatar
// Docs: https://docs.velt.dev/realtime-collaboration/presence/customize-ui/create-your-own-ui

const AvatarComponent = ({ imageUrl }) => (
    <Avatar.Root className="bg-blackA3 inline-flex border border-white h-[45px] w-[45px] select-none items-center justify-center overflow-hidden rounded-full align-middle">
        <Avatar.Image
            className="h-full w-full rounded-[inherit] object-cover"
            src={
                imageUrl
                    ? imageUrl
                    : "https://vercel.com/api/www/avatar/default?&s=60"
            }
            alt="Avatar"
        />
        <Avatar.Fallback
            className="text-blue11 leading-1 flex h-full w-full items-center justify-center bg-white text-[15px] font-medium"
            delayMs={600}
        >
            CT
        </Avatar.Fallback>
    </Avatar.Root>
);
const MAX_USERS = 3;

const CommentAvatarButton = () => {
    const { userLoading, userError, isLoggedIn, userInfo } = useUserData();

    const loggedInUser = userInfo?.loggedInUser;

    const { client } = useVeltClient();

    const user = isLoggedIn
        ? mapEyvaUserToVeltUser(loggedInUser)
        : {
              userId: new Date().getTime().toString(),
              displayName: "eyva",
              photoUrl:
                  "https://oyxfwfzjcwefttmgauus.supabase.co/storage/v1/object/public/avatars/avatar_eyva.png",
              email: "hello@eyva.ai",
              groupId: "Anonymous",
          };

    const [users, setUsers] = useState([user]);

    useEffect(() => {
        if (client) {
            const presenceElement = client.getPresenceElement();

            presenceElement
                .getOnlineUsersOnCurrentDocument()
                .subscribe((users) => {
                    setUsers([user, ...users]);
                });
        }
    }, [client]);
    return (
        <ul className="cursor-move flex items-center mr-2">
            {users.slice(0, MAX_USERS).map((user, id) => (
                <li
                    className="-mr-2 list-item"
                    key={id}
                    style={{ zIndex: MAX_USERS - id }}
                >
                    <AvatarComponent imageUrl={user.photoUrl} />
                </li>
            ))}
            {users.length > MAX_USERS && (
                <li className="-mr-2 list-item">
                    <Avatar.Root className="bg-blackA3 inline-flex border border-white h-[45px] w-[45px] select-none items-center justify-center overflow-hidden rounded-full align-middle">
                        <Avatar.Fallback
                            className="text-blue11 leading-1 flex h-full w-full items-center justify-center bg-white text-[15px] font-medium"
                            delayMs={600}
                        >
                            +{users.length - MAX_USERS}
                        </Avatar.Fallback>
                    </Avatar.Root>
                </li>
            )}
        </ul>
    );
};

export default CommentAvatarButton;
