import React from "react";

export const RightSideImageLayout = ({
    children,
    imageURL,
    imageShadowColor = "#D2AFFF",
}) => {
    return (
        <div className="p-10 grid gap-5 grid-cols-5 min-h-[690px]">
            <div className="col-span-3">{children}</div>
            <div className="col-span-2">
                <div className="flex-col justify-center overflow-hidden flex min-h-[589px] mt-10 max-h-[690px] w-full p-10">
                    <img
                        loading="lazy"
                        srcSet={imageURL}
                        style={{ boxShadow: `30px -30px ${imageShadowColor}` }}
                        className="object-cover "
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};
