import React from "react";
import * as Tooltip from "@radix-ui/react-tooltip";

const Root = ({ children }) => (
    <Tooltip.Provider>
        <Tooltip.Root delayDuration={0}>{children}</Tooltip.Root>
    </Tooltip.Provider>
);

const Trigger = Tooltip.Trigger;

const Content = React.forwardRef(({ children }, ref) => (
    <Tooltip.Portal>
        <Tooltip.Content
            ref={ref}
            className="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-blue11 select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
            sideOffset={16}
        >
            {children}
            <Tooltip.Arrow className="fill-white" />
        </Tooltip.Content>
    </Tooltip.Portal>
));

Content.displayName = Tooltip.Content.displayName;

export default {
    Root,
    Trigger,
    Content,
};
