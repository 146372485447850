import React from "react";
import Footer from "../_generic/Footer";
import { RightSideImageLayout } from "../_generic/RightSideImageLayout";
import { Layout } from "../_generic/Layout";
import ReactWordcloud from "react-wordcloud-upsiide-fork";
const options = {
    enableTooltip: false,
    deterministic: true,
    fontFamily: "Poppins",
    fontSizes: [20, 50],
    fontStyle: "normal",
    fontWeight: "500",
    padding: 6,
    rotations: 3,
    rotationAngles: [0, 0],
    scale: "sqrt", // sqrt, log, linear
    spiral: "archimedean", // archimedean, rectangular
    transitionDuration: 0,
};
export const Hashtags = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        h2 = "ERROR",
        datePeriod = "ERROR",
        hashtags = [],
        imageURL = "https://res.cloudinary.com/djgzit41g/image/upload/v1701935339/ICE/woman_light_eye_cxzqph.png",
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <RightSideImageLayout
                imageURL={imageURL}
                imageShadowColor="#F660D5"
            >
                <div className="space-y-8 flex text-black flex-col h-full justify-center">
                    <div className="space-y-2">
                        <h1 className=" text-17xl font-semibold leading-[54px]">
                            {h1}
                        </h1>
                        {/* <h2 className="text-gray-500 text-sm">{h2}</h2> */}
                        <h2 className="text-sm">{datePeriod}</h2>
                    </div>

                    <div className="w-[700px] h-[500px]">
                        <ReactWordcloud
                            words={hashtags.map((hashtag) => ({
                                text: `${hashtag.hashtag} (${hashtag.count})`,
                                value: hashtag.count,
                            }))}
                            options={options}
                        />
                    </div>
                </div>
            </RightSideImageLayout>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default Hashtags;
