import ReactEcharts from "echarts-for-react";

import Footer from "../_generic/Footer";

const colors = {
    others: "#d1d5dc",
    reference: "#002aff",
};

// const data = [
//     {
//         topic: "Vitamin A",
//         ts: [
//             { sv: 21620, date: "2020-09-01" },
//             { sv: 27430, date: "2020-10-01" },
//             { sv: 41190, date: "2020-11-01" },
//             { sv: 29680, date: "2020-12-01" },
//             { sv: 38280, date: "2021-01-01" },
//             { sv: 41510, date: "2021-02-01" },
//             { sv: 39840, date: "2021-03-01" },
//             { sv: 37350, date: "2021-04-01" },
//             { sv: 29730, date: "2021-05-01" },
//             { sv: 22440, date: "2021-06-01" },
//             { sv: 27240, date: "2021-07-01" },
//             { sv: 25480, date: "2021-08-01" },
//             { sv: 25520, date: "2021-09-01" },
//             { sv: 31200, date: "2021-10-01" },
//             { sv: 30210, date: "2021-11-01" },
//             { sv: 26640, date: "2021-12-01" },
//             { sv: 30400, date: "2022-01-01" },
//             { sv: 28600, date: "2022-02-01" },
//             { sv: 29830, date: "2022-03-01" },
//             { sv: 30580, date: "2022-04-01" },
//             { sv: 31410, date: "2022-05-01" },
//             { sv: 26560, date: "2022-06-01" },
//             { sv: 33140, date: "2022-07-01" },
//             { sv: 31190, date: "2022-08-01" },
//             { sv: 32730, date: "2022-09-01" },
//             { sv: 36940, date: "2022-10-01" },
//             { sv: 39180, date: "2022-11-01" },
//             { sv: 40290, date: "2022-12-01" },
//             { sv: 44930, date: "2023-01-01" },
//             { sv: 44540, date: "2023-02-01" },
//             { sv: 52700, date: "2023-03-01" },
//             { sv: 46280, date: "2023-04-01" },
//             { sv: 38500, date: "2023-05-01" },
//             { sv: 31360, date: "2023-06-01" },
//             { sv: 31630, date: "2023-07-01" },
//             { sv: 38880, date: "2023-08-01" },
//             { sv: 52860, date: "2023-09-01" },
//         ],
//         itemColor: "#d1d5dc",
//     },
//     {
//         topic: "Backuchiol",
//         ts: [
//             { sv: 2720, date: "2020-09-01" },
//             { sv: 3230, date: "2020-10-01" },
//             { sv: 4930, date: "2020-11-01" },
//             { sv: 3470, date: "2020-12-01" },
//             { sv: 3810, date: "2021-01-01" },
//             { sv: 3490, date: "2021-02-01" },
//             { sv: 3390, date: "2021-03-01" },
//             { sv: 3520, date: "2021-04-01" },
//             { sv: 2940, date: "2021-05-01" },
//             { sv: 2160, date: "2021-06-01" },
//             { sv: 2330, date: "2021-07-01" },
//             { sv: 2300, date: "2021-08-01" },
//             { sv: 2470, date: "2021-09-01" },
//             { sv: 2530, date: "2021-10-01" },
//             { sv: 2640, date: "2021-11-01" },
//             { sv: 2720, date: "2021-12-01" },
//             { sv: 2660, date: "2022-01-01" },
//             { sv: 2470, date: "2022-02-01" },
//             { sv: 2450, date: "2022-03-01" },
//             { sv: 2870, date: "2022-04-01" },
//             { sv: 2570, date: "2022-05-01" },
//             { sv: 2350, date: "2022-06-01" },
//             { sv: 2560, date: "2022-07-01" },
//             { sv: 2610, date: "2022-08-01" },
//             { sv: 2600, date: "2022-09-01" },
//             { sv: 3230, date: "2022-10-01" },
//             { sv: 3240, date: "2022-11-01" },
//             { sv: 3450, date: "2022-12-01" },
//             { sv: 3660, date: "2023-01-01" },
//             { sv: 3730, date: "2023-02-01" },
//             { sv: 4220, date: "2023-03-01" },
//             { sv: 3580, date: "2023-04-01" },
//             { sv: 3320, date: "2023-05-01" },
//             { sv: 2790, date: "2023-06-01" },
//             { sv: 2770, date: "2023-07-01" },
//             { sv: 2770, date: "2023-08-01" },
//             { sv: 2740, date: "2023-09-01" },
//         ],
//         itemColor: "#d1d5dc",
//     },
//     {
//         topic: "Retinol",
//         ts: [
//             { sv: 181300, date: "2020-09-01" },
//             { sv: 212310, date: "2020-10-01" },
//             { sv: 319410, date: "2020-11-01" },
//             { sv: 253540, date: "2020-12-01" },
//             { sv: 350850, date: "2021-01-01" },
//             { sv: 375740, date: "2021-02-01" },
//             { sv: 372670, date: "2021-03-01" },
//             { sv: 346410, date: "2021-04-01" },
//             { sv: 294130, date: "2021-05-01" },
//             { sv: 201940, date: "2021-06-01" },
//             { sv: 233530, date: "2021-07-01" },
//             { sv: 234280, date: "2021-08-01" },
//             { sv: 226840, date: "2021-09-01" },
//             { sv: 269530, date: "2021-10-01" },
//             { sv: 249160, date: "2021-11-01" },
//             { sv: 237750, date: "2021-12-01" },
//             { sv: 267350, date: "2022-01-01" },
//             { sv: 245840, date: "2022-02-01" },
//             { sv: 257570, date: "2022-03-01" },
//             { sv: 275490, date: "2022-04-01" },
//             { sv: 264760, date: "2022-05-01" },
//             { sv: 235970, date: "2022-06-01" },
//             { sv: 253770, date: "2022-07-01" },
//             { sv: 295950, date: "2022-08-01" },
//             { sv: 317640, date: "2022-09-01" },
//             { sv: 355400, date: "2022-10-01" },
//             { sv: 391860, date: "2022-11-01" },
//             { sv: 421320, date: "2022-12-01" },
//             { sv: 475630, date: "2023-01-01" },
//             { sv: 486960, date: "2023-02-01" },
//             { sv: 549840, date: "2023-03-01" },
//             { sv: 465740, date: "2023-04-01" },
//             { sv: 409620, date: "2023-05-01" },
//             { sv: 338110, date: "2023-06-01" },
//             { sv: 326980, date: "2023-07-01" },
//             { sv: 366720, date: "2023-08-01" },
//             { sv: 418390, date: "2023-09-01" },
//         ],
//         itemColor: "#002aff",
//     },
// ];

const getOptions = ({ data, lang = "en" }) => {
    // Extract dates for the x-axis from one of the time series
    const dates = data[0].ts.map((entry) => entry.date);

    // Create a series for each topic
    const series = data.map((topic) => {
        // Clone the data array
        let seriesData = topic.ts.map((entry) => ({
            value: entry.sv,
            label: { show: false },
        }));

        // Enable the label for the last data point
        if (seriesData.length > 0) {
            seriesData[seriesData.length - 1].label = {
                show: true,
                position: "right",
                formatter: topic.topic,
                // Add font style properties here
                fontStyle: "italic",
                fontWeight: "bold",
                fontFamily: "Verdana",
                fontSize: 12,
            };
        }

        return {
            animation: false,
            name: topic.topic,
            type: "line",
            data: seriesData,
            smooth: true,
            itemStyle: {
                color: topic.itemColor, // Set the color for each series
            },
            lineStyle: {
                color: topic.itemColor, // Set the line color for each series
            },
        };
    });

    let xAxisText = "Date (Last 3 Years)";

    if (lang === "de") {
        xAxisText = "Datum (Letzten 3 Jahre)";
    }

    let yAxisText = "Monthly Search Volume";
    if (lang === "de") {
        yAxisText = "Monatliches Suchvolumen";
    }

    const options = {
        grid: {
            left: "30px", // Adjust as needed
            right: "100px", // Adjust as needed
            top: "10px", // Adjust as needed
            bottom: "30px", // Adjust as needed
            containLabel: true,
        },
        tooltip: {
            trigger: "axis",
        },
        legend: {
            data: data.map((topic) => topic.topic),
            show: false,
        },
        xAxis: {
            type: "category",
            boundaryGap: false,
            data: dates,
            name: xAxisText,
            nameLocation: "middle",
            nameGap: 30,
            axisLabel: {
                formatter: function (value) {
                    const date = new Date(value);
                    return date.toLocaleDateString("en-US", {
                        month: "short",
                        year: "numeric",
                    });
                },
            },
            splitLine: {
                // Add this property for split lines
                show: true,
                lineStyle: {
                    type: "dashed", // Dashed line style
                    color: "#ddd", // You can change the color if needed
                },
            },
        },
        yAxis: {
            type: "value",
            name: yAxisText, // Y-axis title
            nameRotate: 90, // Rotate the Y-axis title
            nameLocation: "middle", // Position of the Y-axis title
            nameGap: 50, // Gap between the Y-axis title and the axis line
            axisLabel: {
                formatter: function (value) {
                    if (value >= 1000000) {
                        return (value / 1000000).toFixed(1) + "M"; // For millions
                    } else if (value >= 1000) {
                        return (value / 1000).toFixed(0) + "k"; // For thousands
                    }
                    if (value === 0) return null;

                    return (value / 1000).toFixed(1) + "k"; // For thousands
                    //return value; // For values less than 1000
                },
            },
            splitLine: {
                // Add this property for split lines
                show: true,
                lineStyle: {
                    type: "dashed", // Dashed line style
                    color: "#ddd", // You can change the color if needed
                },
            },
        },
        series: series,
    };

    return options;
};

const Slide = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
}) => {
    if (!slideContent) slideContent = {};
    const { data } = slideContent;

    for (const d of data) {
        if (d.ref) d.itemColor = colors.reference;
        else d.itemColor = colors.others;
    }

    return (
        <div className="relative bg-white w-full flex flex-col items-center justify-start min-w-[1280px] max-w-[1280px] text-left text-[36px] text-black font-poppins">
            <div className="self-stretch h-[690px] flex flex-col items-center justify-start pt-[60px] px-[60px] pb-[34px] box-border gap-[10px] min-h-[690px]">
                <div className="flex flex-col items-start self-stretch justify-end">
                    <div className="flex flex-row items-center justify-start">
                        <div className="relative font-semibold">
                            Historical Development
                        </div>
                    </div>
                </div>
                <div className="self-stretch flex-1">
                    <ReactEcharts
                        option={getOptions({ data })}
                        opts={{ renderer: "svg" }}
                        style={{ width: "1200px", height: "550px" }}
                    />
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </div>
    );
};

export default Slide;
